<template>
  <section class="bg-blue-lighter pb-4 pt-0 md:pt-4">
    <div class="relative z-10 w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap">
        <div v-if="modules.media[0].fields" class="column w-12/12 md:w-4/12">
          <ImagesPicture :image="modules.media[0].fields" classes="w-full" />
        </div>
        <div
          v-if="modules.content"
          class="column w-12/12 pl-1 md:w-8/12 md:pl-3"
        >
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  .module--cams-quote {
    &::after {
      @apply absolute left-0 top-0 z-0 h-full bg-white;

      content: '';
      width: 25vw;

      @media (min-width: 1680px) {
        width: 30vw;
      }

      @media (max-width: theme('screens.md')) {
        @apply w-full;

        height: 25%;
      }
    }
  }
</style>
