<template>
  <div v-if="modules.columns" class="w-full px-gap2 lg:container">
    <div class="flex flex-row flex-wrap">
      <template v-for="(entry, index) in modules.columns" :key="index">
        <ModularColumn class="module--half-video__column" :column="entry" />
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  :deep(.module--half-video__column:first-of-type h2) {
    @apply mb-1;
    @screen md {
      @apply mr-4/12;
    }
  }
  :deep(.module--half-video__column .video video) {
    @apply inline-block;
    @media (max-width: theme('screens.md')) {
      @apply relative w-screen max-w-none;

      left: -33px;
    }
  }
  :deep(.card--icon) {
    @apply my-1 pl-0;

    @screen md {
      @apply inline-flex;

      margin: 10px 0;
      width: 50%;
    }
  }
  :deep(.card--icon .icon) {
    @apply block;
  }

  :deep(.card--icon h5) {
    text-transform: none;
    letter-spacing: 0;
  }

  :deep(.card--icon svg) {
    @apply hidden;
  }
</style>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>
