<template>
  <div class="px-gap2 lg:container">
    <div class="-mx-gap flex flex-wrap">
      <div v-if="modules.content" class="w-12/12 px-gap md:w-4/12">
        <RichTextRenderer
          :document="modules.content"
          :node-renderers="nodeRenderers"
        />
      </div>
      <div class="mt-2 w-12/12 px-gap md:ml-1/12 md:mt-0 md:w-7/12">
        <div v-if="modules.references" class="wrapper--image relative">
          <Swiper
            ref="leaderSlider"
            v-bind="leaderOptions"
            :modules="[SwiperNavigation]"
          >
            <SwiperSlide
              v-for="(group, i) in leaderArray"
              :key="i"
              ref="imageGroup"
              class="wrapper--group flex w-full flex-wrap bg-white"
            >
              <ImagesNuxtImg
                v-for="(image, index) in group"
                :key="index"
                :classes="'self-start'"
                :url="
                  image.fields.leaderImage.fields.file
                    ? image.fields.leaderImage.fields.file.url
                    : ''
                "
                :style="`animation-delay:0.${index}s`"
                :alt="image.fields.leaderImage.fields.description"
                :lazy="false"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  const chunks = (a, size) =>
    Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
      a.slice(i * size, i * size + size)
    );

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        leaderOptions: {
          loop: true,
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
          autoplay: {
            delay: 3200,
          },
          effect: 'fade',
        },
      };
    },
    computed: {
      leaderArray() {
        const arrays = chunks(this.modules.references, 6);

        return arrays;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .wrapper--group img {
    @apply mx-1/2 mb-1;
    width: calc(33.333% - 20px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .swiper-slide-active.wrapper--group img {
    animation-name: grow;
  }
  .swiper-slide {
    height: auto;
  }

  @keyframes grow {
    0% {
      transform: scale(0) translateY(20px);
      opacity: 0;
    }

    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }
  @keyframes grow {
    0% {
      transform: scale(0) translateY(20px);
      opacity: 0;
    }

    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }
</style>
