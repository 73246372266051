<template>
  <section class="hub--top-fleets relative w-full">
    <div class="wrapper--content w-full px-gap2 pt-2 lg:container md:pt-5">
      <div class="-mx-gap flex flex-wrap">
        <div class="column content relative z-40 w-12/12 md:static md:w-7/12">
          <div
            class="content--logo mb-2 flex items-center md:mb-0 md:block md:items-baseline"
          >
            <ImagesPicture
              v-if="modules.media[0].fields"
              :image="modules.media[0].fields"
              classes="relative top-auto w-auto md:absolute"
            />
            <div>
              <h1
                v-if="modules.json.h1"
                class="mb-0 font-bold text-white md:font-medium"
              >
                {{ modules.json.h1 }}
              </h1>
              <h5 v-if="modules.json.h3" class="text-green">
                {{ modules.json.h3 }}
              </h5>
            </div>
          </div>
          <div v-if="modules.tabs" class="wrapper--links flex flex-wrap">
            <div
              v-for="(tab, index) in modules.tabs"
              :key="index"
              class="link--top-fleets mb-1 md:mb-2"
            >
              <NuxtLink :to="tab.fields.id">
                <RichTextRenderer
                  :document="tab.fields.tabContent"
                  :node-renderers="nodeRenderers"
              /></NuxtLink>
              <div
                class="image--tab-fade pointer-events-none absolute right-0 top-0 z-0 block h-full w-auto opacity-0 duration-200 ease-in-out"
              >
                <ImagesPicture
                  v-if="tab.fields.image.fields"
                  :image="tab.fields.image.fields"
                  classes="h-full"
                />
              </div>
            </div>
            <div
              class="image--hero-fade pointer-events-none absolute right-0 top-0 z-0 hidden h-full w-full bg-contain bg-right bg-no-repeat duration-200 ease-in-out md:block"
              :style="`background-image: url('${resize(
                modules.media[1].fields.image.fields.file
                  ? modules.media[1].fields.image.fields.file.url
                  : ''
              )}')`"
            ></div>
          </div>
        </div>
        <div
          v-if="modules.content"
          class="column text--all-white text--top-fleet-overview relative z-50 mb-2 flex w-12/12 flex-col justify-center md:mb-0 md:w-5/12"
        >
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
    <div
      v-if="modules.media[2].fields"
      class="image--mobile absolute bottom-0 left-0 block h-auto w-full md:hidden"
    >
      <ImagesPicture
        :image="modules.media[2].fields"
        classes="w-full h-auto block"
      />
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: ['modules'],
    setup() {
      const resize = useResize();
      return {
        resize,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .hub--top-fleets {
    min-height: calc(100vh - 120px);
    background: linear-gradient(210.59deg, #00263e 18.58%, #061b28 89.86%);
  }
  .text--top-fleet-overview {
    @media (min-width: theme('screens.md')) {
      max-width: 415px;
      padding-left: 40px;
    }
  }
  .link--top-fleets {
    &:nth-child(odd) {
      max-width: 40%;
      flex-basis: 40%;

      @media (max-width: theme('screens.md')) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    &:nth-child(even) {
      max-width: 60%;
      flex-basis: 60%;

      @media (max-width: theme('screens.md')) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    &:hover {
      .image--tab-fade {
        opacity: 1;
      }

      & ~ .image--hero-fade {
        opacity: 0;
      }

      a::before {
        width: 33px;
      }

      @media (max-width: theme('screens.md')) {
        .image--tab-fade {
          opacity: 0;
        }

        & ~ .image--hero-fade {
          opacity: 1;
        }
      }
    }
  }
  :deep(.link--top-fleets) {
    a {
      @apply relative block no-underline;

      h3 {
        @apply mb-0 text-white;
      }

      h5 {
        @apply text-xs text-green;
      }
    }
    @media (min-width: theme('screens.md')) {
      a::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='33' height='20' viewBox='0 0 33 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.3697 1L18.6976 3.26236L24.7664 8.40025H1V11.5996H24.7664L18.6976 16.7378L21.3697 19L32 9.99993L21.3697 1Z' stroke='%2326D07C'/%3e%3c/svg%3e ");
        left: -43px;
        top: 7px;
        content: '';
        @apply absolute mr-1/2 h-1 w-0 duration-200 ease-in-out;
      }
    }
  }
  .content--logo {
    h1 {
      @media (min-width: theme('screens.md')) {
        font-size: 43px;
        line-height: 60px;
      }
    }

    h5 {
      @media (min-width: theme('screens.md')) {
        font-size: 23px;
        line-height: 32px;
        font-weight: 500;
      }
    }
  }

  :deep(.content--logo) {
    & > picture img {
      top: 70px;
      transform: translate(-70px);

      @media (max-width: theme('screens.md')) {
        transform: none;
        height: 95px;
        top: 0;
      }
    }
  }

  .content {
    @media (max-width: theme('screens.md')) {
      .content--logo {
        & > div {
          @apply ml-gap;

          h1 {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }
  }
</style>
