<template>
  <section>
    <div class="w-full px-gap2 lg:container">
      <div v-if="modules.columns" class="-mx-gap flex flex-wrap">
        <div
          v-for="(column, index) in modules.columns"
          :key="index"
          class="column w-4/12 px-gap"
        >
          <RichTextRenderer
            v-if="column.fields.content"
            :document="column.fields.content"
            :node-renderers="nodeRenderers"
            :mark-renderers="markRenderers"
          />
        </div>
      </div>
    </div>
    <ImagesPicture
      v-if="modules.media[0].fields"
      :image="modules.media[0].fields"
      classes="w-full"
    />
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers, markRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  :deep(.column) {
    .p--giant-stat {
      @media (max-width: theme('screens.md')) {
        @apply mb-0;
      }
    }

    h2 {
      @apply text-white;

      @media (max-width: theme('screens.md')) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
</style>
