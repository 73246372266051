<template>
  <section
    id="platform-modal-slider"
    class="bg-left-bottom bg-repeat-x"
    :style="`background-image: url('${bgImage}')`"
  >
    <div class="container">
      <div ref="boundingBox">
        <Swiper
          ref="platformModalSlider"
          v-bind="platformModalSlider"
          :loop="true"
          class="rotator--platform-modal flex py-3"
          :modules="[SwiperNavigation]"
          @click="openModal($event)"
        >
          <SwiperSlide
            v-for="(slide, index) in modules.slides"
            :key="index"
            class="wrapper__slide"
            :data-link="slide.fields.link"
          >
            <CardsFullImageModalCard
              :slide="slide"
              :index="index"
            ></CardsFullImageModalCard>
          </SwiperSlide>
          <div
            slot="button-next"
            class="next--blue absolute right-0 z-20 cursor-pointer select-none rounded-full"
          >
            <svg
              width="52"
              height="52"
              viewBox="0 0 93 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
              <path
                d="M49.7067 30.4653L65.7412 46.4998L49.7067 62.5343"
                stroke="white"
                stroke-width="5"
              />
              <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
            </svg>
          </div>
        </Swiper>
      </div>
    </div>
    <div
      v-for="(slide, index) in modules.slides"
      :ref="slide.fields.link"
      :key="index"
      class="modal modal--row"
      @click.prevent="closeModalBg"
    >
      <div
        class="modal__inner"
        :style="`background-image: url('${resize(
          modules.media[index].fields.image.fields.file
            ? modules.media[index].fields.image.fields.file.url
            : ''
        )}');`"
      >
        <span class="modular-modal-close" @click.prevent="closeModal">
          <svg
            width="29"
            height="30"
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="28.3536"
              y1="1.35355"
              x2="0.353553"
              y2="29.3536"
              stroke="white"
            />
            <line
              x1="0.353553"
              y1="0.646447"
              x2="28.3536"
              y2="28.6464"
              stroke="white"
            />
          </svg>
        </span>
        <div class="text--all-white container relative opacity-100">
          <RichTextRenderer
            :document="slide.fields.content"
            :node-renderers="nodeRenderers"
            :mark-renderers="markRenderers"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers, markRenderers } from '~/utils/contentful-helpers';
  import { fadeIn, fadeOut } from '~/utils/html-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    setup() {
      const resize = useResize();
      const swiper = useSwiper();
      return {
        resize,
        swiper,
      };
    },
    data() {
      return {
        selectedIndex: '',
        platformModalSlider: {
          navigation: {
            nextEl: '.next--blue',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 4.5,
          allowTouchMove: false,
          breakpoints: {
            0: {
              slidesPerView: 1.3,
            },
            736: {
              slidesPerView: 4.5,
            },
          },
        },
      };
    },
    computed: {
      bgImage() {
        return this.modules.json.dots;
      },
    },
    mounted() {
      let innerWidth = window.innerWidth;
      let leftDistance = this.$refs.boundingBox.getBoundingClientRect().left;
      let setWidth = innerWidth - leftDistance;

      this.$refs.boundingBox.style.width = setWidth + 'px';

      window.onresize = () => {
        innerWidth = window.innerWidth;
        leftDistance = this.$refs.boundingBox.getBoundingClientRect().left;
        setWidth = innerWidth - leftDistance;

        this.$refs.boundingBox.style.width = setWidth + 'px';
      };
    },
    methods: {
      openModal($event) {
        const clickedElm = $event.clickedSlide;
        if (clickedElm === null) {
          return;
        }

        const clickedLink = clickedElm.getAttribute('data-link');
        fadeIn(this.$refs[clickedLink][0], 'block');
      },
      closeModal() {
        const modalParent = event.target.closest('.modal');
        fadeOut(modalParent);
      },
      closeModalBg() {
        if (event.target == event.currentTarget) {
          fadeOut(event.currentTarget);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #platform-modal-slider {
    .rotator--platform-modal {
      @apply pb-2 pt-3;
      @screen md {
        margin-left: 20px;
        padding-bottom: 120px;
      }
    }
    .wrapper__slide {
      transition: 0.2s ease-in-out;

      &:hover {
        transform: scale(1.2);
        margin: 0 20px !important;
      }
    }

    .next--blue {
      top: 50%;
      transform: translate(0, -50%);
      margin-right: 30px;
      box-shadow: 0;
      transition: 0.2s ease-in-out;
      height: 52px;
      width: 52px;

      @media (max-width: theme('screens.md')) {
        width: 40px;
        height: 40px;

        svg {
          width: 40px;
          height: 40px;
        }
      }

      svg {
        circle {
          transition: 0.2s ease-in-out;
        }
      }

      &:hover {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        svg circle {
          fill-opacity: 1 !important;
        }
      }
    }
    .modal .modal__inner {
      &::after {
        @apply absolute left-0 top-0 h-full w-full bg-black;

        content: '';
        opacity: 0.4;
        @media (max-width: theme('screens.md')) {
          height: 120%;
        }
      }

      .container {
        @apply my-3;
      }
    }
    :deep(.row) {
      @apply -mx-gap flex flex-wrap;
    }

    :deep(.column) {
      @apply px-gap;
    }

    :deep(.modal__inner hr) {
      border: 0;
      border-bottom: 1px solid #fff;
      margin-bottom: theme('spacing.2');
    }
  }
</style>
