<template>
  <div class="px-gap2 pt-1 lg:container">
    <div class="flex flex-wrap pt-1/4 lg:hidden lg:flex-nowrap">
      <div
        v-if="modules.content"
        class="title padding--bottom-25-lg flex w-12/12 items-center pb-1/4 lg:w-auto lg:pr-space2"
      >
        <RichTextRenderer
          :document="modules.content"
          :node-renderers="nodeRenderers"
        />
      </div>
    </div>
    <div class="padding--bottom-25-sm flex flex-nowrap overflow-auto lg:pt-1/4">
      <div
        v-if="modules.content"
        class="title padding--bottom-25-lg hidden w-12/12 items-center pb-1/4 lg:flex lg:w-auto lg:pr-space2"
      >
        <RichTextRenderer
          :document="modules.content"
          :node-renderers="nodeRenderers"
        />
      </div>
      <div
        v-for="(card, index) in modules.cards"
        :key="index"
        class="wrapper--link padding--bottom-25-lg flex flex-shrink-0 md:w-6/12 lg:w-5/12 lg:flex-shrink"
        :class="
          index + 1 === modules.cards.length
            ? 'border-l-1 pl-1 lg:pl-space2'
            : 'pr-1 lg:border-l-1 lg:px-space2'
        "
      >
        <div class="wrapper--img hidden flex-shrink-0 lg:block">
          <ImagesPicture
            v-if="card.fields.image.fields"
            :image="card.fields.image.fields"
            classes="object-cover max-w-none w-full h-full"
          />
        </div>
        <LinksLocaleLink
          v-if="card.fields.link"
          class="block text-sm font-bold leading-5 text-blue-dark underline hover:text-blue focus:text-blue lg:pl-space2 lg:text-base lg:leading-7"
          :link="card.fields.link"
          >{{ card.fields.content }}</LinksLocaleLink
        >
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .module--index-news {
    &:after {
      @apply absolute right-0 top-0 z-10 h-full w-3;
      content: '';
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(240, 246, 254, 1) 100%
      );
      @screen lg {
        display: none;
      }
    }
  }
  .border-l-1,
  .lg\:border-l-1 {
    border-color: #dce4e9;
  }
  .lg\:container {
    padding: 25px 0 0 40px;
    @screen md {
      @apply px-3;
      padding-top: 25px;
      max-width: 1300px;
    }
  }
  .title {
    h5 {
      @apply mb-0;
      @screen lg {
        white-space: pre-wrap;
      }
    }
  }
  .wrapper--img {
    width: 115px;
    height: 65px;
    @screen lg {
      width: 141px;
      height: 79px;
    }
  }
  .padding--bottom-25-lg {
    @screen lg {
      padding-bottom: 25px;
    }
  }
  .padding--bottom-25-sm {
    @media (max-width: theme('screens.lg')) {
      padding-bottom: 25px;
    }
  }
  .wrapper--link {
    @media (max-width: theme('screens.md')) {
      width: calc(100vw - 145px);
    }
  }
</style>
