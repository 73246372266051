<template>
  <div class="w-full px-gap2 lg:container">
    <div class="-mx-gap mb-4 flex flex-wrap">
      <div class="column w-12/12 self-center px-gap md:w-7/12">
        <RichTextRenderer
          :document="getNestedProperty(modules, 'content', false)"
        />
      </div>
      <div class="column w-12/12 px-gap md:w-5/12">
        <ImagesPicture :image="getNestedProperty(modules, 'media[0].fields')" />
      </div>
    </div>
    <div
      v-for="(productGroup, index) in modules.references"
      :key="index"
      class="product-group -mx-gap flex flex-wrap pb-4"
    >
      <template
        v-if="getNestedProperty(productGroup, 'fields.productGroupName', false)"
        :id="getNestedProperty(productGroup, 'fields.productGroupName', false)"
      >
        <div
          v-if="productGroup.fields.hideGroupName !== true"
          class="column product-group__header w-12/12 px-gap"
        >
          <h3>
            {{
              getNestedProperty(productGroup, 'fields.productGroupName', false)
            }}
          </h3>
        </div>
        <template v-for="(product, i) in productGroup.fields.products" :key="i">
          <CardsProductCard
            v-if="
              product.sys?.contentType?.sys &&
              getNestedProperty(
                product,
                'fields.modelsPageProductImage',
                false
              ) &&
              product.sys.contentType.sys.id === 'productPage'
            "
            :product="product"
            :modules="modules"
            class="column mt-1 w-12/12 px-gap md:w-4/12"
          />
          <CardsTheCard
            v-else-if="product.sys?.contentType?.sys?.id === 'card'"
            :card="product.fields"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { getNestedProperty } from '~/utils';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>
