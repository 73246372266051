<template>
  <div>
    <div class="lines-illustration absolute left-0 z-0 hidden md:block">
      <img
        v-if="modules.json['lines']"
        :src="modules.json['lines']"
        :alt="'lines'"
      />
    </div>
    <div class="wrapper--content relative z-10 w-full px-gap2 lg:container">
      <div class="content__column -mx-gap flex flex-wrap">
        <ModularColumn
          v-for="(column, index) in modules.columns"
          :key="index"
          :column="column"
        />
      </div>
      <div class="-mx-gap mt-3 flex flex-wrap">
        <div class="column ml-3/12 w-6/12 px-gap text-center md:ml-0 md:w-3/12">
          <ImagesPicture :image="modules.media[0].fields" />
        </div>
        <div class="column quote-text w-12/12 px-gap md:w-9/12">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  .lines-illustration {
    top: -160px;
  }

  .content__column {
    .col:first-of-type {
      margin-bottom: 0;
    }

    .col:not(:first-of-type) {
      h2 {
        margin-bottom: 0;
      }

      h5 {
        @screen md {
          max-width: 215px;
        }
      }
    }
  }

  .quote-text {
    :deep(h4) {
      @screen md {
        font-size: 28px;
        line-height: 46px;
        font-weight: 400;
      }
    }

    :deep(h5) {
      margin-bottom: 0;
    }

    :deep(i) {
      font-family: 'Times New Roman', Times, serif;
      color: theme('colors.blue.dark');
      font-size: 15px;
    }
  }
</style>
