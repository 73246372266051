<template>
  <section class="flex flex-row flex-wrap bg-blue-dark">
    <div class="container order-last md:order-first">
      <div class="-mx-gap flex flex-wrap">
        <div
          class="copy copy--wrapper column w-12/12 px-gap py-3 md:w-5/12 md:py-5"
          :class="{
            'text--all-white':
              modules.json && modules.json.textAllWhite === true,
          }"
        >
          <RichTextRenderer
            v-if="modules.content"
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
          <span
            v-if="modules.links"
            class="btn btn--blue cursor-pointer"
            @click="
              openVideoModal(
                modules.links[0].fields.linkUrl
                  ? modules.links[0].fields.linkUrl
                  : '',
                'Modal Video'
              )
            "
            >{{ modules.links[0].fields.text }}</span
          >
        </div>
      </div>
    </div>
    <template v-if="modules.media">
      <template v-if="modules.media.length >= 2">
        <div
          v-if="!loading"
          class="image image--wrapper relative right-0 top-0 h-full w-screen overflow-hidden hover:cursor-pointer md:absolute md:w-screen-1/2"
          @click="
            openVideoModal(
              modules.media[1].fields.id ? modules.media[1].fields.id : '',
              'Modal Video'
            )
          "
        >
          <ImagesPicture
            v-if="
              modules.media &&
              modules.media[0].sys.contentType.sys.id === 'image'
            "
            :image="modules.media[0].fields"
            classes="w-full h-full object-cover"
          />
          <VideosTheVideo
            v-else-if="
              modules.media &&
              modules.media[1].sys.contentType.sys.id === 'video'
            "
            :video="modules.media[0].fields"
            class="h-full w-full overflow-hidden"
          />
          <div class="absolute bottom-0 z-10 mb-2 ml-2">
            <p
              v-if="content.copy['watchVideo']"
              class="mb-0 inline text-xl font-medium text-white"
            >
              {{ content.copy['watchVideo'] }}
            </p>
            <IconsSvgPlayIconBackground class="svg--play-icon" />
          </div>
        </div>
        <template v-if="modules.media[1].fields.type === 'YouTube'">
          <ModalsYoutubeEmbedModal
            :is-open="isOpen"
            :close-location="'outer'"
            :video="{ id: ytId }"
            :youtube-player-state="ytPlayerState"
            @closeVideoModal="closeModal"
          />
        </template>
        <ModalsTheModal
          v-else
          :is-open="isOpen"
          :close-location="'outer'"
          @close="closeModal"
        >
          <VideosTheVideo :video="modules.media[1].fields" />
        </ModalsTheModal>
      </template>
      <template v-else>
        <div
          class="image image--wrapper noOverlay relative right-0 top-0 h-full w-screen overflow-hidden md:absolute md:w-screen-1/2"
        >
          <ImagesPicture
            v-if="
              modules.media &&
              modules.media[0].sys.contentType.sys.id === 'image'
            "
            :image="modules.media[0].fields"
            classes="w-full h-full object-cover"
          />
        </div>
      </template>
    </template>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { localeMappings } from '~/constants/locales';
  import { nodeRenderers } from '~/utils/contentful-helpers';
  import { report } from '~/utils/telemetry';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    async setup() {
      const { locale } = useI18n();
      const openModal = ref(false);
      const ytId = ref(null);
      const isOpen = ref(false);
      const ytPlayerState = ref('unstarted');

      const { data, error, pending } = await useAsyncGql({
        operation: 'CorpWebDB',
        variables: {
          id: '2TSaUfMlDqsaI0YMmSwCGi',
          locale: localeMappings[locale.value],
        },
      });

      if (error.value) {
        report(error.value);
      }

      return {
        content: data.value?.corpwebDb?.jsonContent ?? '',
        loading: pending.value,
        openModal,
        ytId,
        isOpen,
        ytPlayerState,
      };
    },
    methods: {
      openVideoModal(url, type) {
        if (type === 'Modal Video') {
          url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          this.ytId =
            url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
          this.ytPlayerState = 'play';
          this.isOpen = true;
        }
      },
      closeModal() {
        this.isOpen = false;
        this.ytPlayerState = 'unstarted';
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.copy--wrapper) {
    h5 {
      @apply text-blue-light;
    }

    h2,
    p,
    h3,
    h1 {
      @apply text-white;
    }
  }
  .text--all-white h5 {
    @apply text-white;
  }
  .image--wrapper {
    :deep(video) {
      @apply h-full w-full object-cover;
    }

    &::after {
      content: '';
      @apply absolute bottom-0 left-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
    &.noOverlay {
      &::before,
      &::after {
        @apply hidden;
      }
    }
  }
  .image__play {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: calc(-69px / 2);
    top: auto;
    svg {
      width: 69px;
      height: 69px;
    }
    @screen md {
      left: calc(-133px / 2);
      top: 50%;
      bottom: auto;
      transform: translate(0, -50%);
      svg {
        width: 133px;
        height: 133px;
      }
    }
  }
</style>
