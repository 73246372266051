<template>
  <div id="cw-image-grid">
    <div class="w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap">
        <div class="column grid-top relative mb-1 w-12/12 px-gap md:mb-2">
          <RichTextRenderer
            v-if="modules.content"
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
          <div
            v-if="modules.tabs[0].fields.image.fields"
            class="image-overlaid"
          >
            <ImagesPicture :image="modules.tabs[0].fields.image.fields" />
          </div>
        </div>
        <div
          ref="gridWrapper"
          class="grid-wrapper m-auto my-0 flex flex-row flex-wrap"
        >
          <div
            v-for="(cw_image, index) in modules.media"
            :key="index"
            ref="gridImages"
            class="img-wrap"
          >
            <ImagesPicture :image="cw_image.fields" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    data() {
      return {
        currentTransition: 0,
      };
    },
    mounted() {
      const gridImages = this.$refs.gridImages;
      const gridWrapper = this.$refs.gridWrapper;
      const onScreen = this.isOnScreen;

      gridImages.forEach((gridImage) => {
        gridImage.style.transitionDelay = this.currentTransition + 's';
        this.currentTransition += 0.01;
      });

      function handlescroll() {
        if (onScreen(gridWrapper, 3)) {
          gridImages.forEach((gridImage) => gridImage.classList.add('scaled'));
        } else {
          gridImages.forEach((gridImage) =>
            gridImage.classList.remove('scaled')
          );
        }
      }

      document.addEventListener('scroll', debounce(handlescroll, 10));
    },
    unmounted() {
      document.removeEventListener('scroll', this.handlescroll);
    },
  };
</script>

<style lang="scss" scoped>
  .grid-wrapper {
    width: 90%;

    & > .img-wrap {
      width: 20%;
      flex-basis: 20%;
      border-radius: 7px;
      padding: 11px;
      transform: scale(0) translateY(20px);
      opacity: 0;
      will-change: transform, scale;
      transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: theme('screens.md')) {
        padding: 5px;
      }

      :deep(img) {
        display: block;
        width: 100%;
        border-radius: 7px;
      }

      &.scaled {
        transform: scale(1) translateY(0);
        opacity: 1;
      }
    }
  }

  .grid-top > p {
    display: none;
  }

  .image-overlaid {
    position: absolute;
    z-index: 2;
    top: 75%;
    right: 12%;
    width: 42%;
  }
</style>
