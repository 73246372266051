<template>
  <section id="solutions-image-column">
    <div class="-mx-gap flex flex-wrap justify-center text-center">
      <div
        v-if="modules.media[0].fields"
        class="column solutions-column__image w-12/12 md:w-6/12"
      >
        <ImagesPicture :image="modules.media[0].fields" />
      </div>
      <div
        class="column solutions-column-content flex w-12/12 flex-col items-start justify-center px-2 text-left md:w-6/12 md:pl-4"
      >
        <div v-if="modules.content" class="content">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  #solutions-image-column {
    :deep(.solutions-column__image) {
      img {
        @apply block h-full w-12/12 object-cover;
      }

      @media (max-width: theme('screens.md')) {
        @apply mb-0;
      }
    }

    :deep(.content) {
      @apply px-0 py-4;

      max-width: 428px;

      ul {
        @apply m-0 mt-3 list-none p-0;

        columns: 2;
        margin-bottom: 15px;
      }

      li {
        @apply mb-2 flex pl-0;

        max-width: 200px;
        margin-right: 10px;
        height: 72px;
      }

      li::before {
        content: '→';
        padding-right: 12px;
        color: theme('colors.blue.DEFAULT');
        font-family: Times, serif;
        font-style: normal;
        font-weight: bold;
      }

      @media (max-width: theme('screens.md')) {
        ul {
          columns: 1;
        }

        li {
          @apply h-auto max-w-full;
        }
      }
    }
  }
</style>
