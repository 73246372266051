<template>
  <div class="bg-blue-lighter px-gap2 lg:container">
    <Swiper
      v-if="modules.slides"
      ref="historySlider"
      v-bind="sliderHistoryOptions"
      :modules="[SwiperNavigation]"
      @slide-change="onSlideChange"
    >
      <SwiperSlide v-for="(slide, index) in modules.slides" :key="index">
        <div class="carousel__slide -mx-gap flex h-full flex-wrap">
          <div
            v-if="slide.fields.content"
            class="wrapper--content relative w-12/12 px-gap md:w-6/12"
          >
            <RichTextRenderer
              :document="slide.fields.content"
              :node-renderers="nodeRenderers"
            />
          </div>
          <div class="wrapper--image relative w-12/12 px-gap md:w-6/12">
            <ImagesPicture
              v-if="slide.fields.image.fields"
              :image="slide.fields.image.fields"
              classes="object-cover w-full"
              :lazy="true"
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="slider--thumbs relative mt-2">
      <div
        ref="sliderThumbs"
        class="slider--thumbs__wrapper flex flex-nowrap overflow-x-auto"
      >
        <template v-if="modules.slides">
          <div
            v-for="(slide, index) in modules.slides"
            :key="index"
            class="slider--thumbs__tab relative w-[150px] flex-shrink-0 cursor-pointer md:w-[230px] md:pr-[40px]"
            :class="{ active: index == selectedIndex }"
            @click="goToSlide(index)"
            @keydown="goToSlide(index)"
          >
            <span
              class="label mb-1/2 block text-[14px] font-bold leading-[17px] text-blue-dark opacity-50"
              :class="{ active: index == selectedIndex }"
              v-html="slide.fields.label"
            ></span>
            <span
              class="progress progress--dark absolute bottom-0 left-0 w-full bg-blue-dark opacity-50"
            ></span>
            <span
              class="progress progress--light absolute bottom-0 left-0 bg-blue"
              :class="{
                'active showProgress': index == selectedIndex,

                pauseProgress:
                  index < selectedIndex &&
                  selectedIndex <= modules.slides.length - 1,
              }"
            ></span>
          </div>
        </template>
      </div>
    </div>
    <div slot="button-prev" class="prev--arrow">
      <svg
        width="93"
        height="93"
        viewBox="0 0 93 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
        <path
          d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
          stroke="white"
          stroke-width="5"
        />
        <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
      </svg>
    </div>
    <div slot="button-next" class="next--arrow">
      <svg
        width="93"
        height="93"
        viewBox="0 0 93 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
        <path
          d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
          stroke="white"
          stroke-width="5"
        />
        <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
      </svg>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const swiperRef = ref();
      const onSwiperInit = (swiper) => {
        swiperRef.value = swiper;
      };
      return {
        swiperRef,
        onSwiperInit,
      };
    },
    data() {
      return {
        sliderHistoryOptions: {
          loop: true,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
        },
        selectedIndex: 0,
        clickIndex: null,
      };
    },
    methods: {
      onSlideChange(swiper) {
        const nextIndex = swiper.realIndex;
        this.selectedIndex = nextIndex;
        const currentLabel = document.querySelectorAll('.slider--thumbs__tab')[
          nextIndex
        ];
        const labelContainer = this.$refs.sliderThumbs;
        labelContainer.scrollLeft = currentLabel.offsetLeft;
      },
      goToSlide(index) {
        this.clickIndex = index;
        this.swiperRef.slideTo(index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .module--history-slider {
    @apply py-4;

    max-width: 1120px;
    @screen md {
      @apply p-4;

      border-radius: 40px;
    }
  }
  .module--history-slider :deep(p) {
    @apply text-blue-dark;
  }
  .slider--thumbs__tab .label {
    &.active {
      @apply opacity-100;
    }
  }
  .slider--thumbs__wrapper {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .slider--thumbs {
    &::after {
      content: '';
      width: 80px;
      background: linear-gradient(
        266.98deg,
        #f0f6fe 3.78%,
        rgba(240, 246, 254, 0) 93.08%
      );
      @apply absolute right-0 top-0 z-10 h-full;
    }
    .swiper-slide-active {
      span {
        @apply opacity-100;
      }
    }
  }

  .progress {
    height: 5px;
  }
  .progress--light {
    @apply z-10;

    width: 0;
    &.pauseProgress {
      width: 100%;
      animation: none;
    }
  }
  .prev--arrow,
  .next--arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    top: 65%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
    }

    @screen md {
      top: 50%;
      width: 71px;
      height: 71px;

      svg {
        width: 71px;
        height: 71px;
      }
    }
  }

  .prev--arrow {
    left: 0;
    margin-left: 0;
    @screen md {
      margin-left: -35px;
    }

    svg {
      transform: scaleX(-1);
    }
  }

  .next--arrow {
    right: 0;
    margin-right: 0;
    @screen md {
      margin-right: -35px;
    }
  }

  /*.showProgress {
    animation-name: progress;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  } */
</style>
