<template>
  <section id="solutions-hero-section">
    <span v-if="modules.media[1].fields" class="block md:hidden"
      ><ImagesPicture :image="modules.media[1].fields"
    /></span>
    <div class="hero--solutions relative z-10 py-4">
      <div class="w-full px-gap2 lg:container">
        <div class="-mx-gap flex flex-wrap">
          <div
            v-if="modules.media[0].fields"
            class="column hero--solutions__image relative hidden w-12/12 pt-1 md:inline-block md:w-6/12 md:pt-0"
          >
            <ImagesPicture :image="modules.media[0].fields" />
          </div>
          <div
            v-if="modules.content"
            class="column flex w-12/12 flex-col items-start justify-center pb-5 pt-2 text-left md:w-6/12 md:pb-0 md:pl-4 md:pt-0"
          >
            <RichTextRenderer
              :document="modules.content"
              :node-renderers="nodeRenderers"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="ring"
      class="hero--solutions_ring sm:hidden md:inline-block"
    ></div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    mounted() {
      const ring = this.$refs.ring;
      const tabletScreen = 736;

      if (window.innerWidth > tabletScreen) {
        window.addEventListener('scroll', function () {
          const scrolled = window.pageYOffset;
          const rate = scrolled * 0.3;
          ring.style.transform = 'translate3d(0px, ' + rate + 'px, 0px)';
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  #solutions-hero-section {
    :deep(.hero--solutions) {
      background: linear-gradient(to left, #00263e 70%, transparent 30%);

      h1,
      p {
        @apply text-white;
      }

      p {
        @apply text-base font-light leading-6;

        & > strong {
          @apply font-bold;
        }
      }

      .hero--solutions__image img {
        @apply relative z-20;
      }
    }

    .hero--solutions_ring {
      @apply absolute rounded-full;

      z-index: -10;
      width: 350px;
      height: 350px;
      left: -100px;
      top: -45px;
      border: 30px solid #f0f6fe;

      @media (max-width: theme('screens.xl')) {
        width: 450px;
        height: 450px;
        top: -150px;
      }
    }

    @media (max-width: theme('screens.md')) {
      .hero--solutions {
        @apply bg-blue-dark py-0;
      }
    }
  }
</style>
