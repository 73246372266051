<template>
  <section>
    <div class="relative z-10 w-full px-gap2 pt-1 lg:container md:pt-2">
      <div class="-mx-gap flex flex-wrap">
        <div class="column w-12/12 px-gap">
          <NuxtLink
            :to="modules.json.home"
            class="link--back p--xs-eyebrow p--xs block font-bold text-white no-underline"
          >
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="inline"
            >
              <path
                d="M4.57202 7.98315L1.10492 4.5L4.57202 1.01684"
                stroke="white"
              />
              <path
                d="M1.51611 4.5H13"
                stroke="white"
                stroke-linecap="square"
              />
            </svg>
            {{ modules.json.home_text }}
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="container relative z-10 pb-3 pt-3 md:pb-5 md:pt-4">
      <div class="-mx-gap flex flex-wrap">
        <div
          class="column wrapper--content flex w-12/12 flex-col justify-center px-gap md:w-6/12"
        >
          <div class="content--logo text--all-white relative">
            <ImagesPicture :image="modules.media[0].fields" />
            <div>
              <RichTextRenderer
                :document="modules.content"
                :node-renderers="nodeRenderers"
                :mark-renderers="markRenderers"
              />
            </div>
          </div>
          <div
            class="wrapper--stats flex w-full flex-wrap justify-between md:w-4/5 md:flex-nowrap"
          >
            <div
              class="hr mb-2 mt-1 block w-full border-b-1 border-green md:hidden"
            ></div>
            <div
              v-for="(tab, index) in modules.tabs"
              :key="index"
              class="text--all-white"
            >
              <RichTextRenderer
                :document="tab.fields.tabContent"
                :node-renderers="nodeRenderers"
              />
            </div>
          </div>
        </div>
        <div
          class="column wrapper--image flex w-12/12 flex-col justify-center px-gap md:ml-1/12 md:w-5/12"
        >
          <ImagesPicture :image="modules.media[1].fields" />
        </div>
      </div>
    </div>
    <div
      class="hero--bg absolute bottom-0 right-0 z-0 h-auto w-full md:h-full md:w-auto"
    >
      <div class="hidden h-full w-full md:block md:w-auto">
        <ImagesPicture
          :image="modules.media[2].fields"
          classes="h-full w-full md:w-auto block"
        />
      </div>
      <div class="block h-full w-full md:hidden md:w-auto">
        <ImagesPicture
          :image="modules.media[3].fields"
          classes="h-full w-full md:w-auto block"
        />
      </div>
    </div>
    <h5
      class="text--learn-more mb-0 hidden flex-col items-center justify-center pb-2 text-white md:flex"
    >
      {{ modules.json.learn_more }}
      <svg
        width="15"
        height="9"
        viewBox="0 0 15 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9058 0.851562L7.58664 7.17074L1.23835 0.880815"
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </h5>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers, markRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  .module--top-fleets-hero {
    background: linear-gradient(210.59deg, #00263e 18.58%, #061b28 89.86%);
  }
  :deep(.content--logo) {
    .p--xs-eyebrow {
      @apply text-green;
    }

    & > picture {
      left: -70px;
      @apply absolute;
    }

    @media (max-width: theme('screens.md')) {
      display: flex;

      & > picture {
        margin-right: 10px;
        width: 130px;
        @apply relative left-auto;

        img {
          width: 27px;
        }
      }
    }
  }

  :deep(.wrapper--image) {
    img {
      box-shadow: 0 4px 39px rgba(0, 0, 0, 0.53);
    }
  }

  :deep(.wrapper--stats) {
    h2 {
      @apply mb-0;
    }
  }

  .link--back {
    @media (min-width: 1200px) {
      transform: translate(-70px, 0);
    }
  }
</style>
