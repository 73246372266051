<template>
  <LinksLocaleLink
    :data-testid="`nav-link-${content.text.toLowerCase()}`"
    :link="content.href"
    class="link--nav flex"
    :class="colWidth"
    :alt="content.title"
  >
    <span v-if="image === 'icon'"
      ><ImagesNuxtImg
        v-if="content.icon"
        :url="content.icon"
        :alt="''"
        class="w-[36px]"
    /></span>
    <span
      v-else-if="image === 'image'"
      class="relative mb-1/4 h-0 w-full overflow-hidden pb-[56.25%]"
    >
      <ImagesNuxtImg
        v-if="content.image"
        :url="content.image"
        :lazy="false"
        class="filter--none absolute bottom-0 left-0 right-0 top-0 h-full w-full"
        :alt="content.title ? content.title : ''"
    /></span>
    <div>
      <p
        class="link--title font-medium duration-100 ease-in-out"
        :class="type === 'no-desc' ? 'mb-1' : image !== 'image' ? 'mb-1/4' : ''"
        @click="trackLink(content.href, content.text)"
      >
        {{ content.text }}
      </p>
      <p v-if="content.description">{{ content.description }}</p>
      <p
        v-if="image === 'image'"
        class="link--blue uppercase tracking-normal text-blue"
      >
        {{ content.more }}
      </p>
    </div>
  </LinksLocaleLink>
</template>
<script>
  export default {
    props: ['content', 'image', 'type'],
    setup() {
      const { analyticsTrack } = useAnalytics();

      return {
        analyticsTrack,
      };
    },
    computed: {
      colWidth() {
        const widths = {
          full: '',
          half: 'half-width',
          article: 'flex-col article--featured',
          thirds: 'thirds-width flex-col article--featured px-gap',
        };

        return widths[this.type];
      },
    },
    methods: {
      trackLink(link, text) {
        this.analyticsTrack('Nav Link Clicked', { link, text });
      },
    },
  };
</script>
<style lang="scss">
  .link--nav {
    width: 100%;

    &.half-width {
      max-width: 50%;
      flex-basis: 50%;
      padding-right: 30px;

      @media (max-width: 850px) {
        max-width: 100%;
        flex-basis: 100%;
        padding-right: 0;
      }
    }

    &.article--featured {
      p {
        margin-bottom: 5px;
      }
    }

    &.thirds-width {
      max-width: 33.3333%;
      flex-basis: 33.3333%;

      @media (max-width: 850px) {
        max-width: 100%;
        flex-basis: 100%;
      }

      span {
        margin-right: 0;
      }

      p {
        font-size: 15px;
        line-height: 21px;
      }
    }

    img.filter--none {
      margin-bottom: 10px;
    }

    &:hover {
      p.link--title {
        color: theme('colors.blue.DEFAULT') !important;
      }
      img {
        filter: invert(53%) sepia(69%) saturate(6933%) hue-rotate(194deg)
          brightness(102%) contrast(98%);
      }

      img.filter--none {
        filter: none;
      }
    }
  }
</style>
