<template>
  <div>
    <div class="flex w-full justify-between px-gap2 lg:container">
      <h1 v-if="modules.json.title" class="mr-1 w-auto whitespace-nowrap">
        {{ modules.json.title }}
      </h1>
      <div
        class="w-full border-1 border-black"
        style="height: 2px; margin-top: 28px"
      ></div>
      <div class="hidden md:flex">
        <div slot="button-prev" class="prev--arrow">
          <IconsPreviousArrowIcon />
        </div>
        <div slot="button-next" class="next--arrow">
          <IconsNextArrowIcon />
        </div>
      </div>
    </div>
    <div class="w-full px-gap pb-1 pr-0 pt-1 lg:container lg:px-gap">
      <Swiper
        ref="productSwiper"
        v-bind="postsOptions"
        :modules="[SwiperNavigation]"
      >
        <template v-for="(slide, index) in posts" :key="index">
          <SwiperSlide v-if="slide.backgroundImage">
            <div class="carousel__slide h-full">
              <LinksLocaleLink
                v-if="slide.blogPageSlug"
                :link="`/customers/${getNestedProperty(slide, 'blogPageSlug', '')}`"
                class="wrapper--card m-1/2 mt-1 block text-center transition-all duration-100 ease-in-out"
              >
                <div class="wrapper--image flex">
                  <ImagesNuxtImg
                    v-if="slide.backgroundImage.url"
                    :url="slide.backgroundImage.url"
                    :alt="slide.backgroundImage.description"
                    :classes="'object-cover'"
                    :lazy="false"
                  />
                </div>
                <div class="wrapper--content">
                  <p
                    v-if="modules.json.category_label"
                    class="mb-0 mt-1 text-xxs font-bold uppercase text-blue"
                  >
                    {{ modules.json.category_label }}
                  </p>
                  <h4
                    v-if="slide.title"
                    class="text-xl hover:underline"
                    style="margin-bottom: 15px"
                  >
                    {{ slide.title }}
                  </h4>
                  <p v-if="slide.summary">{{ slide.summary }}</p>
                </div>
              </LinksLocaleLink>
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script>
  import { localeMappings } from '~/constants/locales';
  import { getNestedProperty } from '~/utils';
  import { report } from '~/utils/telemetry';

  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    async setup(props) {
      const { locale } = useI18n();

      const { data, error } = await useAsyncGql({
        operation: 'RelatedStoriesSlider',
        variables: {
          locale: localeMappings[locale.value],
          category: props.modules.json.category_slug,
        },
      });

      if (error.value) {
        report(error.value.message);
      }

      return {
        document: props.modules,
        posts: data.value?.blogPostCollection?.items ?? '',
        postsOptions: {
          loop: false,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
            disabledClass: 'swiper-button-disabled',
          },
          lazy: {
            loadPrevNext: true,
          },
          allowTouchMove: true,
          breakpoints: {
            0: {
              slidesPerView: 1.2,
              slidesPerGroup: 1,
            },
            400: {
              slidesPerView: 1.3,
              slidesPerGroup: 1,
            },
            500: {
              slidesPerView: 1.5,
              slidesPerGroup: 1,
            },
            600: {
              slidesPerView: 1.75,
              slidesPerGroup: 1,
            },
            700: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            800: {
              slidesPerView: 2.25,
              slidesPerGroup: 1,
            },
            1000: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.wrapper--image) {
    @apply flex items-center overflow-hidden;

    img {
      height: 254px;
      width: auto;
      max-width: none;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  .wrapper--content {
    text-align: left;
  }

  .prev--arrow,
  .next--arrow {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: 0.25;
      cursor: default;

      :deep(circle) {
        fill: #333333;
      }
    }
  }

  .prev--arrow {
    margin-left: 15px;
    margin-right: 10px;
  }

  .swiper-slide {
    height: auto;
  }
</style>
