<template>
  <div>
    <div class="lg:px-auto px-gap lg:container">
      <div class="-mx-gap flex flex-wrap md:-mx-gap2">
        <div class="column w-12/12 px-gap pb-2 text-center md:pb-4">
          <RichTextRenderer :document="modules.content" />
        </div>
        <div class="column w-12/12 px-gap">
          <Swiper
            ref="relatedProductsSwiper"
            v-bind="relatedProductsSwiper"
            class="swiper__slide w-full py-2"
            :modules="[SwiperNavigation]"
            @swiper="onProductSwiper"
          >
            <SwiperSlide
              v-for="(product, index) in modules.references"
              :key="index"
            >
              <CardsProductCard
                v-if="
                  getNestedProperty(
                    product,
                    'fields.modelsPageProductImage',
                    false
                  )
                "
                :product="product"
                :modules="modules"
                :lazy="true"
                class="column mt-1 w-12/12 px-gap md:w-4/12"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          v-if="modules.links"
          class="column w-12/12 px-gap pt-2 text-center md:pt-4"
        >
          <ButtonsTheButton :button="modules.links[0].fields" />
        </div>
      </div>
    </div>
    <div slot="button-prev" class="prev--arrow" @click="prevSlide()">
      <svg
        width="93"
        height="93"
        viewBox="0 0 93 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
        <path
          d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
          stroke="white"
          stroke-width="5"
        />
        <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
      </svg>
    </div>
    <div slot="button-next" class="next--arrow" @click="nextSlide()">
      <svg
        width="93"
        height="93"
        viewBox="0 0 93 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
        <path
          d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
          stroke="white"
          stroke-width="5"
        />
        <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
      </svg>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { getNestedProperty } from '~/utils';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const productSwiperRef = ref(null);

      const onProductSwiper = (swiper) => {
        productSwiperRef.value = swiper;
      };

      return {
        productSwiperRef,
        onProductSwiper,
      };
    },
    data() {
      return {
        relatedProductsSwiper: {
          loop: true,
          lazy: {
            loadPrevNext: true,
          },
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          slidesPerView: 1,
          allowTouchMove: false,
          breakpoints: {
            415: {
              slidesPerView: 1,
            },
            736: {
              allowTouchMove: false,
              slidesPerView: 3,
              loop: false,
            },
          },
        },
      };
    },

    methods: {
      nextSlide() {
        this.productSwiperRef.slideNext();
      },
      prevSlide() {
        this.productSwiperRef.slidePrev();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .swiper__slide {
    @apply py-2;
  }
  :deep(.swiper-slide) {
    height: auto;
    .column {
      @apply mt-0 h-full w-12/12;
    }
  }
  .prev--arrow,
  .next--arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
    }

    @screen md {
      top: 50%;
      width: 71px;
      height: 71px;

      svg {
        width: 71px;
        height: 71px;
      }
    }
  }

  .prev--arrow {
    left: 0;
    margin-left: 0;
    @screen md {
      margin-left: 35px;
    }

    svg {
      transform: scaleX(-1);
    }
  }

  .next--arrow {
    right: 0;
    margin-right: 0;
    @screen md {
      margin-right: 35px;
    }
  }
</style>
