<template>
  <div>
    <div class="product__wrapper relative h-full border-1">
      <LinksLocaleLink
        class="block"
        :link="`/products/models/${product.fields.slug}`"
      >
        <ImagesNuxtImg
          :url="
            getNestedProperty(
              product,
              'fields.modelsPageProductImage.fields.file.url',
              ''
            )
          "
          :modifier="'pad'"
          :alt="product.fields.modelName"
          :classes="'w-full'"
          :lazy="false"
        />
        <div class="text__wrapper px-2 pb-3 pt-2">
          <p class="p--subhead text-blue-dark">
            {{ getNestedProperty(product, 'fields.productSubHead', false) }}
          </p>
          <p>
            {{ getNestedProperty(product, 'fields.productBrief', false) }}
          </p>
        </div>
      </LinksLocaleLink>
      <LinksLocaleLink
        v-if="getNestedProperty(product, 'fields.coverImage', false)"
        :link="`/products/models/${product.fields.slug}`"
        class="img--overlay absolute left-0 top-0 flex h-full w-full justify-center bg-cover opacity-0 transition-all duration-150 ease-in-out"
        :style="`background-image: url('${getNestedProperty(
          product,
          'fields.coverImage.fields.file.url',
          false
        )}')`"
      >
        <span
          v-if="getNestedProperty(modules, 'json.productDetails', false)"
          class="btn btn--blue mb-2 mt-auto cursor-pointer"
          >{{ getNestedProperty(modules, 'json.productDetails', false) }}</span
        >
      </LinksLocaleLink>
    </div>
  </div>
</template>

<script>
  import { getNestedProperty } from '~/utils';

  export default {
    props: {
      product: {
        type: Object,
        required: true,
      },
      modules: {
        type: Object,
        required: false,
        default: null,
      },
      notLazy: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product__wrapper {
    @apply transition-all duration-150;

    border-color: #d4d4d4;
    border-radius: 8px;

    img {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background: theme('colors.gray.lighter');
    }
    .img--overlay {
      border-radius: 8px;
    }
    &:hover {
      @apply shadow-lg;

      transform: scale(1.05);
      .img--overlay {
        @apply opacity-100;
      }
    }
  }
  .text__wrapper {
    .p--subhead {
      @apply mb-1/2;
    }
  }
</style>
