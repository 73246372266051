<template>
  <div id="solutions-scroll">
    <div
      ref="ringTwo"
      class="solutions-scroll__ring hidden md:inline-block"
    ></div>
    <div class="w-full px-gap2 lg:container">
      <div class="solutions-scroll__wrapper flex flex-wrap md:-mx-gap">
        <div
          ref="stickyContentWrap"
          class="column solutions-scroll__content relative w-12/12 md:w-5/12"
        >
          <div
            v-if="modules.tabs"
            ref="stickyContainer"
            class="solutions-scroll__content-container"
          >
            <template v-for="(tab, index) in modules.tabs" :key="index">
              <div class="solutions-scroll__image--m">
                <div class="mb-8 block md:hidden">
                  <ImagesPicture
                    v-if="tab.fields.image.fields"
                    :image="tab.fields.image.fields"
                    :alt="getNestedProperty(tab, 'fields.id', false)"
                  />
                </div>
                <div
                  :id="
                    tab.fields.id
                      ? tab.fields.id.toLowerCase().replace(/ /g, '-')
                      : tab.sys.id
                  "
                  class="content mb-16 md:mb-1"
                  :class="{ active: index === 0 }"
                >
                  <RichTextRenderer
                    v-if="tab.fields.tabContent"
                    :document="tab.fields.tabContent"
                    :node-renderers="nodeRenderers"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          class="column solutions-scroll__images hidden w-12/12 md:inline-block md:w-7/12"
        >
          <template v-for="(tab, index) in modules.tabs" :key="index">
            <div
              ref="solutionsScrollImage"
              :data-id="
                tab.fields.id
                  ? tab.fields.id.toLowerCase().replace(/ /g, '-')
                  : tab.sys.id
              "
              class="solutions-scroll__image mt-4"
            >
              <ImagesPicture
                :image="tab.fields.image.fields"
                :alt="getNestedProperty(tab, 'fields.id', false)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { getNestedProperty } from '~/utils';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    unmounted() {
      this.onUnAttachListeners();
    },
    mounted() {
      this.onAttachListeners();
      window.addEventListener(
        'resize',
        debounce(() => {
          this.onUnAttachListeners();
          this.onAttachListeners();
        }, 100)
      );
    },
    methods: {
      onAttachListeners() {
        const tabletScreen = 736;
        if (window.innerWidth > tabletScreen) {
          window.addEventListener(
            'scroll',
            debounce(this.allWindowMethods, 10)
          );
          window.addEventListener(
            'resize',
            debounce(this.allWindowMethods, 10)
          );
          window.addEventListener('load', debounce(this.allWindowMethods, 10));
        }
      },
      onUnAttachListeners() {
        window.removeEventListener('scroll', this.allWindowMethods);
        window.removeEventListener('resize', this.allWindowMethods);
        window.removeEventListener('load', this.allWindowMethods);
      },
      toggleActiveClassOnContent() {
        const windowScrollTop = window.scrollY;
        const stickThreshold = Math.abs(windowScrollTop + 500);
        this.$refs.solutionsScrollImage.forEach((selectedStickyImage) => {
          const currentImgID = selectedStickyImage.dataset.id;
          const currentContent = document.getElementById(currentImgID);
          const currentImgTop =
            selectedStickyImage.getBoundingClientRect().top +
            document.documentElement.scrollTop;
          const currentImgBottom =
            Math.abs(currentImgTop) + selectedStickyImage.offsetHeight;
          if (
            stickThreshold >= currentImgTop &&
            stickThreshold <= currentImgBottom &&
            !currentContent.classList.contains('active')
          ) {
            document
              .querySelector('.content.active')
              .classList.remove('active');
            currentContent.classList.add('active');
          }
        });
      },
      positionContentContainerOnScroll() {
        const windowScrollTop = window.scrollY;
        const stickThreshold = Math.abs(windowScrollTop + 500);
        const { stickyContainer, stickyContentWrap } = this.$refs;
        if (!stickyContainer || !stickyContentWrap) {
          return;
        }
        const stickyHeight = stickyContainer.offsetHeight;
        const containerHeight = stickyContentWrap.offsetHeight;
        const containerWidth = stickyContentWrap.offsetWidth;

        const contentFromTop =
          stickyContentWrap.getBoundingClientRect().top +
          document.documentElement.scrollTop -
          window.scrollY;

        const stickyImages = this.$refs.solutionsScrollImage;
        let lastStickyImage = stickyImages.at(-1);
        const lastStickyImageBottom =
          lastStickyImage.getBoundingClientRect().top +
          document.documentElement.scrollTop +
          lastStickyImage.offsetHeight;
        const containPos = Math.abs(contentFromTop);
        if (lastStickyImageBottom <= stickThreshold + 65) {
          stickyContainer.style.bottom = '0';
          stickyContainer.style.top = 'auto';
          stickyContainer.style.position = 'absolute';
        } else if (
          contentFromTop <= 300 &&
          containerHeight - stickyHeight >= containPos
        ) {
          stickyContainer.style.bottom = 'auto';
          stickyContainer.style.top = '100px';
          stickyContainer.style.position = 'fixed';
          stickyContainer.style.width = containerWidth + 'px';
        } else if (contentFromTop >= 0) {
          stickyContainer.style.bottom = 'auto';
          stickyContainer.style.top = '0';
          stickyContainer.style.position = 'absolute';
        }
      },
      addRingScrollListener() {
        const scrolled = window.pageYOffset;
        const rate = scrolled * 0.3;

        if (this.$refs.ringTwo) {
          this.$refs.ringTwo.style.transform =
            'translate3d(0px, ' + rate + 'px, 0px)';
        }
      },
      allWindowMethods() {
        this.toggleActiveClassOnContent();
        this.positionContentContainerOnScroll();
        this.addRingScrollListener();
      },
    },
  };
</script>
<style lang="scss" scoped>
  #solutions-scroll {
    .solutions-scroll__image {
      margin-bottom: 160px;

      &:first-of-type {
        @apply mt-0;
      }

      &:last-of-type {
        @apply mb-0;
      }
    }

    @media (max-width: theme('screens.md')) {
      .container {
        @apply max-w-full;
      }

      .solutions-scroll__image--m {
        margin-top: 120px;

        &:first-of-type {
          @apply mt-0;
        }
      }
    }

    .solutions-scroll__content .solutions-scroll__content-container {
      will-change: top, bottom, transform;

      @media (min-width: theme('screens.md')) {
        @apply absolute bottom-auto top-0 w-12/12;

        padding-right: 120px;
      }
    }

    :deep(.content) {
      @apply hidden;

      ul {
        @apply mt-0;

        padding-bottom: 35px;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 20px;

        li {
          @apply pl-0;

          p {
            @apply mb-0;
          }
        }
      }

      p:nth-child(3) {
        @apply mb-0;
      }

      @media (max-width: theme('screens.md')) {
        @apply inline;

        ul {
          padding-bottom: 20px;
        }
      }

      &.active {
        @apply inline;
      }
    }

    .solutions-scroll__ring {
      @apply absolute self-center;

      margin-top: -250px;
      right: -80px;
      z-index: -10;
      width: 350px;
      height: 350px;
      border-radius: 50%;
      border: 30px solid #f0f6fe;

      @media (min-width: theme('screens.xl')) {
        width: 450px;
        height: 450px;
        margin-top: -350px;
      }

      @media (max-width: theme('screens.lg')) {
        @apply right-0;
      }
    }
  }
</style>
