<template>
  <div class="w-full">
    <Swiper
      v-if="modules.media"
      ref="sliderImage"
      v-bind="sliderImageOptions"
      :modules="[SwiperNavigation]"
    >
      <SwiperSlide v-for="(slide, index) in modules.media" :key="index">
        <div class="carousel__slide h-full">
          <div class="wrapper--image relative">
            <ImagesPicture
              v-if="slide.fields"
              :image="slide.fields"
              classes="object-cover h-full max-w-none absolute md:relative w-full"
              :lazy="true"
            />
            <div class="btn btn--white-full absolute bg-white text-blue-dark">
              {{ modules.customText[index].fields.content }}
            </div>
          </div>
        </div>
      </SwiperSlide>
      <div slot="button-prev" class="prev--arrow">
        <svg
          width="93"
          height="93"
          viewBox="0 0 93 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
          <path
            d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
            stroke="white"
            stroke-width="5"
          />
          <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
        </svg>
      </div>
      <div slot="button-next" class="next--arrow">
        <svg
          width="93"
          height="93"
          viewBox="0 0 93 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="46.5" cy="46.5" r="46.5" fill="#0384FB" />
          <path
            d="M49.7069 30.4653L65.7413 46.4998L49.7069 62.5343"
            stroke="white"
            stroke-width="5"
          />
          <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
        </svg>
      </div>
    </Swiper>
  </div>
</template>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        sliderImageOptions: {
          loop: true,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: true,
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .wrapper--image {
    min-height: 249px;

    img {
      @apply h-full object-cover;
    }
  }
  .btn--white-full {
    @apply hidden;

    bottom: 30px;
    right: 30px;
    @screen md {
      @apply block;
    }
  }
  .prev--arrow,
  .next--arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
    }

    @screen md {
      width: 93px;
      height: 93px;

      svg {
        width: 93px;
        height: 93px;
      }
    }
  }

  .prev--arrow {
    left: 0;
    margin-left: 20px;

    svg {
      transform: scaleX(-1);
    }
  }

  .next--arrow {
    right: 0;
    margin-right: 20px;
  }
</style>
