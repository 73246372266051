<template>
  <div class="lg:px-auto px-gap lg:container">
    <div class="-mx-gap flex flex-wrap md:-mx-gap2">
      <div class="column w-12/12 px-gap pb-2 text-center md:pb-4">
        <RichTextRenderer :document="modules.content" />
      </div>
    </div>
    <div
      class="columns__wrapper -mx-gap flex flex-col flex-wrap items-center md:-mx-gap2 md:flex-row md:flex-nowrap md:items-start md:justify-between"
    >
      <template v-for="(column, index) in modules.columns" :key="index">
        <template v-if="index !== modules.columns.length - 1">
          <ModularColumn :key="index" :column="column" />
          <div :key="index + 'dotted'" class="line--blue-dotted"></div>
        </template>
        <template v-else
          ><ModularColumn :key="index" :column="column"
        /></template>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .module--how-it-works {
    .line--blue-dotted {
      @apply relative mb-2 border-1 border-dashed border-blue;

      height: 116px;
      width: 1px;
      margin-top: auto;

      @screen md {
        @apply mb-0;

        margin-top: 6%;
        height: 1px;
        width: 116px;
      }
    }
    .columns__wrapper {
      :deep() {
        .column {
          max-width: 260px;
        }
        .column img {
          @apply mb-2 w-full;

          max-width: 230px;
          max-height: 145px;
        }
      }
    }
  }
</style>
<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>
