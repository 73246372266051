<template>
  <div class="px-gap2 lg:container">
    <TabsTheTabs
      v-model="isActive"
      class="tabs__container"
      tab-classes="text-lg uppercase tracking-wide opacity-50 text-blue-dark font-bold mx-1/2 transition-colors duration-200 ease-in-out mb-1 md:mb-0 text-center"
      header-classes="justify-center flex mb-2 md:mb-4 flex-wrap"
      tabs-type="SimpleTabs"
      deep-children="true"
      @update:model-value="updateTabs"
    >
      <TabsTheTab
        v-if="modules.json.tabs[0]"
        :title="modules.json.tabs[0]"
        class="flex flex-wrap justify-center"
        :is-active="0 === isActive"
      >
        <WidgetsLeaderWidget
          v-for="(leader, index) in leadership"
          :key="index"
          :person="leader"
        />
      </TabsTheTab>
      <TabsTheTab
        v-if="modules.json.tabs[1]"
        :title="modules.json.tabs[1]"
        class="flex flex-wrap justify-center"
        :is-active="1 === isActive"
      >
        <WidgetsLeaderWidget
          v-for="(member, index) in board"
          :key="index"
          :person="member"
        />
      </TabsTheTab>
    </TabsTheTabs>
  </div>
</template>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const isActive = ref(0);
      return {
        isActive,
      };
    },
    computed: {
      leadership() {
        const list = this.modules.references;
        const leadership = list.filter(
          (leader) => leader.fields.leadershipCategory === 'Management'
        );

        return leadership;
      },
      board() {
        const list = this.modules.references;
        const boardMembers = list.filter(
          (leader) => leader.fields.boardMember == true
        );
        return boardMembers;
      },
    },
    methods: {
      updateTabs(index) {
        this.isActive = index;
      },
    },
  };
</script>
<style lang="scss" scoped>
  :deep(li.tab__selected) {
    opacity: 1;
    border-bottom: 1px solid theme('colors.blue.DEFAULT');
  }

  :deep(ul.tabs__header li:not(.tab__selected)) {
    border-bottom: 1px solid transparent;
    &:hover {
      opacity: 0.6;
      border-bottom: 1px solid rgba(0, 38, 62, 0.3);
    }
  }
</style>
