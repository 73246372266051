<template>
  <section id="platform-data-carousel">
    <div class="w-full px-gap2 lg:container">
      <div
        id="index-case-wrapper"
        ref="platformBoundingBox"
        class="-mx-gap flex flex-wrap"
      >
        <Swiper
          v-if="modules.slides"
          v-bind="platformSlider"
          class="rotator--index-case flex w-full"
          :modules="[SwiperNavigation]"
          @swiper="onPlatformSlider"
        >
          <SwiperSlide
            v-for="(slide, index) in amendedSlides"
            :key="index"
            class="slide--platform-data relative flex h-full flex-col bg-white"
          >
            <ClientOnly>
              <LinksLocaleLink :link="slide.fields.link">
                <ImagesPicture
                  v-if="slide.fields.image.fields"
                  :image="slide.fields.image.fields"
                  classes="w-full block"
                  :lazy="true"
                />
                <div class="wrapper__copy">
                  <RichTextRenderer
                    v-if="slide.fields.content"
                    :document="slide.fields.content"
                    :node-renderers="nodeRenderers"
                  />
                </div>
              </LinksLocaleLink>
            </ClientOnly>
          </SwiperSlide>
          <div
            class="next--blue absolute right-0 z-20 cursor-pointer select-none rounded-full"
            @click="next"
          >
            <svg
              width="52"
              height="52"
              viewBox="0 0 93 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="46.5" cy="46.5" r="46.5" fill="#00263e" />
              <path
                d="M49.7067 30.4653L65.7412 46.4998L49.7067 62.5343"
                stroke="white"
                stroke-width="5"
              />
              <path d="M65.7415 46.5H22.4484" stroke="white" stroke-width="5" />
            </svg>
          </div>
        </Swiper>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    setup() {
      const platformSliderRef = ref(null);
      const resize = useResize();

      const onPlatformSlider = (swiper) => {
        platformSliderRef.value = swiper;
      };

      return {
        resize,
        platformSliderRef,
        onPlatformSlider,
      };
    },
    data() {
      return {
        platformSlider: {
          spaceBetween: 20,
          loop: true,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 2.5,
          allowTouchMove: false,
          loopAddBlankSlides: true,
          breakpoints: {
            0: {
              slidesPerView: 1.5,
            },
            736: {
              slidesPerView: 2.5,
            },
            1000: {
              slidesPerView: 2.5,
            },
          },
        },
      };
    },
    computed: {
      amendedSlides() {
        const slidesLength = this.modules.slides.length;
        if (slidesLength < 6) {
          const copy = [...this.modules.slides];
          const slide = this.modules.slides.at(-3);
          copy.push(slide);

          return copy;
        } else {
          return this.modules.slides;
        }
      },
      bgImage() {
        return this.resize(
          this.modules.media[0].fields.image.fields.file
            ? this.modules.media[0].fields.image.fields.file.url
            : ''
        );
      },
    },
    mounted() {
      let innerWidth = window.innerWidth;
      let leftDistance =
        this.$refs.platformBoundingBox.getBoundingClientRect().left;
      let setWidth = innerWidth - leftDistance;
      this.$refs.platformBoundingBox.style.width = setWidth + 'px';
      this.platformSliderRef.update();

      window.onresize = () => {
        innerWidth = window.innerWidth;
        leftDistance =
          this.$refs.platformBoundingBox.getBoundingClientRect().left;
        setWidth = innerWidth - leftDistance;

        this.$refs.platformBoundingBox.style.width = setWidth + 'px';
      };
    },
    methods: {
      next() {
        this.platformSliderRef.slideNext();
      },
    },
  };
</script>

<style lang="scss" scoped>
  #platform-data-carousel {
    background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/4Lwgve2ZWLlzJMluFxUqTo/135b83992a8ea54344c5c4181d31691c/image_4.png');
    background-repeat: repeat-x;
    background-position: bottom;

    .rotator--index-case {
      padding-top: 30px;
      padding-bottom: 60px;
      padding-left: 20px;
      margin-left: -20px;

      .slide--platform-data {
        flex: 1 0 auto;

        a {
          opacity: 0.2;
          transition: 0.2s ease-in-out;
        }

        &.swiper-slide-active {
          a {
            opacity: 1;
          }
        }

        &.swiper-slide-next {
          a {
            opacity: 1;
          }
        }

        &.swiper-slide-prev {
          transition: 0.2s ease-in-out;
          opacity: 0;
        }
      }

      .next--blue {
        width: 93px;
        height: 93px;
        top: 50%;
        transform: translate(0, -50%);
        margin-right: 30px;

        @media (max-width: theme('screens.md')) {
          width: 40px;
          height: 40px;
          margin-right: 50px;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        svg {
          @apply rounded-full duration-200 ease-in-out;

          box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.25);

          circle {
            @apply duration-200 ease-in-out;
          }

          path {
            @apply duration-200 ease-in-out;
          }
        }

        &:hover {
          svg {
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
            transform: translate(0, -2px);
          }
        }
      }
    }

    a.slide--platform-data {
      @apply no-underline;
    }

    .slide--platform-data {
      @apply opacity-100 duration-200 ease-in-out;

      box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.15);
    }

    .wrapper__copy {
      padding: 20px 25px;

      h3 {
        @apply mb-0;
      }

      p {
        @apply mb-0 mt-0;
      }
    }
  }

  :deep(.wrapper__copy) {
    h5 {
      @apply text-green;
    }
  }
</style>
