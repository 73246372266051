<template>
  <div
    v-if="
      ready &&
      !dataLoading &&
      sortedDepartments.length > 0 &&
      sortedLocations.length > 0
    "
    id="open-positions"
    class="relative overflow-hidden"
  >
    <div ref="toolsSpacer" class="filter-spacer h-0 md:h-[88px]"></div>
    <section
      ref="filterToolsOuter"
      :class="{
        'fixed left-0 right-0 z-10 w-full': isSticky,
        'relative top-0 block w-full md:absolute': !isSticky,
      }"
      class="roles-filter--tools-outer bg-white"
    >
      <section
        ref="filterTools"
        class="roles-filter--tools container relative flex flex-col flex-wrap items-center justify-between bg-white pb-1 pt-2 md:flex-row md:pb-1 md:pt-1"
      >
        <InputsCareersFiltersSearchInput
          ref="searchInput"
          class="search-input mt-1 md:mr-1 md:mt-0"
          :cleared="clearedSearch"
          :custom-search-index-arr="customSearchResponseIndexArr"
          @search-results="showSearchResults"
        />
        <div v-if="device === 'd'" class="justify-right flex flex-row">
          <ButtonsSemanticButton
            v-for="item in buttons"
            :id="item.name"
            :key="item.id"
            ref="filterbutton"
            v-model:pressed="item.active"
            class="filter-button justify-between md:mr-1"
            :text="item.name"
            :classes="'btn btn--blue-secondary !mb-0 btn--icon-dropdown btn--blue-secondary:hover'"
            :class="{ active: item.active }"
            @click="onFilterPress($event, item.id, item.name)"
            @keydown="onFilterPress($event, item.id, item.name)"
          >
          </ButtonsSemanticButton>
        </div>
        <ButtonsSemanticButton
          v-if="device === 'm'"
          class="relative block"
          :text="
            filterModalButtonTextTrigger + '(' + totalNumFilterTools + ') +'
          "
          :classes="'btn btn--blue mt-1 md:mt-0 justify-center'"
          @click="openModal = !openModal"
        />

        <ul
          v-if="device === 'd'"
          ref="filterListMenu"
          class="filter-container md:t-75 absolute"
          :class="{ active: active }"
        >
          <template v-if="activeFilter === 'departments'">
            <li v-for="(item, index) in filterList.departments" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
              >
                <input
                  :id="index"
                  v-model="filters.departments"
                  type="checkbox"
                  :name="index"
                  :value="index"
                  class="self-center"
                  @change="updateUrlParam"
                /><span>{{ index }}</span>
                <span class="ml-1/4 self-center text-xs italic text-blue"
                  >{{
                    jobListCalculations.departments[index]
                      ? jobListCalculations.departments[index]
                      : 0
                  }}{{
                    jobListCalculations.departments[index] === 0 ||
                    jobListCalculations.departments[index] > 1
                      ? ' jobs'
                      : ' job'
                  }}</span
                >
              </label>
            </li>
          </template>
          <template v-if="activeFilter === 'locations'">
            <li v-for="(item, index) in filterList.locations" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
              >
                <input
                  :id="index"
                  v-model="filters.locations"
                  type="checkbox"
                  :value="index"
                  :name="index"
                  class="self-center"
                  @change="updateUrlParam"
                />
                <span>{{ index }}</span>
                <span class="ml-1/4 self-center text-xs italic text-blue"
                  >{{
                    jobListCalculations.locations[index]
                      ? '(' + jobListCalculations.locations[index]
                      : '(' + 0
                  }}{{
                    (jobListCalculations.locations[index] &&
                      jobListCalculations.locations[index] > 1) ||
                    !jobListCalculations.locations[index]
                      ? ' jobs)'
                      : ' job)'
                  }}
                </span></label
              >
            </li>
          </template>
          <template v-if="activeFilter === 'type'">
            <li v-for="(item, index) in filterList.type" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
              >
                <input
                  :id="index"
                  v-model="filters.type"
                  :value="index"
                  type="checkbox"
                  :name="index"
                  class="self-center"
                  @change="updateUrlParam"
                /><span>{{ index }}</span></label
              >
            </li>
          </template>
        </ul>
      </section>
    </section>
    <section
      class="list--results container relative m-auto mb-2 h-full w-12/12 overflow-x-hidden p-1"
    >
      <p class="list--results-messaging text-center">
        <template v-if="resultsFound">
          {{ textStrings.resultsFoundMsg }}
          <b>{{ numOfResults }} {{ textStrings.role }}</b>
          {{ textStrings.conjunctionValueOne }}
          <b
            >{{ resultsNumOfDepartments }}
            {{
              processFilterMessageResultsValue(textStrings.filters[0], null)
            }}</b
          >
          {{ textStrings.conjunctionValueTwo }}
          <b
            >{{ resultsNumOfLocations }}
            {{
              processFilterMessageResultsValue(null, textStrings.filters[1])
            }}</b
          >
        </template>
        <template v-else>{{ textStrings.noResultsFoundMsg }}</template>
      </p>

      <ButtonsSemanticButton
        v-if="isFilterActive"
        :class="isClearing ? 'clearing' : null"
        class="btn--icon-clear-all relative block uppercase text-blue"
        :text="filterButtonTriggerClearAll"
        :classes="'font-bold text-xs mt-1 md:mt-0 m-auto justify-center'"
        @click="clearAllFilters"
      />
      <template v-if="searchActive">
        <ul
          v-for="(result, index) in processSearchResults"
          :key="index"
          class="department"
        >
          <template v-if="result[1].length !== 0">
            <li>
              <h3 class="list--results-item-department mt-1 uppercase">
                {{ result[0] }}
              </h3>
              <ul>
                <li
                  v-for="role in result[1]"
                  :key="result[1][role]"
                  class="list--results-item-inner mb-0 p-0"
                >
                  <NuxtLink
                    ref="jobLink"
                    class="list--results-item-link position flex items-center border-b-1 border-gray-lightest no-underline"
                    :to="`${locale === 'en-US' ? '' : '/' + locale}/company/careers/roles/${role[1]}?gh_jid=${role[1]}${sourceParam}`"
                    @click="
                      trackRoleLinkClick(
                        `/company/careers/roles/${role[1]}?gh_jid=${role[1]}${sourceParam}`
                      )
                    "
                  >
                    <div class="mb-1 mt-1 flex flex-col">
                      <h4
                        class="list--results-item-title block font-medium text-blue-dark"
                      >
                        {{ role[0] }}
                      </h4>
                      <div
                        class="list--results-item-location font-regular block flex-grow"
                      >
                        {{ role[2] }}
                      </div>
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </template>
      <template v-else>
        <ul
          v-for="(jobs, index) in jobList"
          :id="jobs[0]"
          ref="department"
          :key="index[jobs[0]]"
          class="department"
        >
          <div v-if="jobs[1].length !== 0">
            <li>
              <h3
                ref="department"
                class="list--results-item-department mt-1 uppercase"
              >
                {{ jobs[0] }}
              </h3>
              <ul>
                <li
                  v-for="job in jobs[1]"
                  :key="jobs[1][job]"
                  ref="role"
                  :data-location="job[2]"
                  class="list--results-item-inner mb-0 p-0"
                >
                  <NuxtLink
                    ref="jobLink"
                    class="list--results-item-link position flex items-center border-b-1 border-gray-lightest no-underline"
                    :to="`${locale === 'en-US' ? '' : '/' + locale}/company/careers/roles/${job[1]}?gh_jid=${job[1]}${sourceParam}`"
                    @click="
                      trackRoleLinkClick(
                        `/company/careers/roles/${job[1]}?gh_jid=${job[1]}${sourceParam}`
                      )
                    "
                  >
                    <div class="mb-1 mt-1 flex flex-col">
                      <h4
                        class="list--results-item-title block font-medium text-blue-dark"
                      >
                        {{ job[0] }}
                      </h4>
                      <div
                        class="list--results-item-location font-regular block flex-grow"
                      >
                        {{ job[2] }}
                      </div>
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </div>
        </ul>
      </template>
    </section>
    <ModalsTheModal
      bg="bg-white"
      class="careers-modal"
      :close-stroke="'black'"
      :is-open="openModal"
      @close="closeModal"
    >
      <div class="block h-full pl-1 pr-1 pt-2">
        <div class="block w-12/12 text-left">
          <h2 class="mb-0 text-blue-dark">
            {{ modalTextHeadline }}
          </h2>
        </div>
        <div class="roles-filter--tools block w-full">
          <ButtonsSemanticButton
            v-model:pressed="buttons[0].active"
            class="btn--icon-dropdown-minimal filter-button block w-full justify-between"
            :text="buttons[0].name"
            :classes="'btn'"
            :class="{ active: buttons[0].active }"
            @click="onFilterPress($event, buttons[0].id, buttons[0].name)"
            @keydown="onFilterPress($event, buttons[0].id, buttons[0].name)"
          />
          <ul ref="filterListMenuModal" class="filter-container">
            <li v-for="(item, index) in filterList.departments" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
                ><input
                  :id="index"
                  v-model="filters.departments"
                  :value="index"
                  type="checkbox"
                  :name="index"
                  class="self-center"
                  @change="updateUrlParam"
                /><span>{{ index }}</span>
                <span class="ml-1/4 self-center text-xs italic text-blue"
                  >{{
                    jobListCalculations.departments[index]
                      ? jobListCalculations.departments[index]
                      : 0
                  }}{{
                    jobListCalculations.departments[index] === 0 ||
                    jobListCalculations.departments[index] > 1
                      ? ' jobs'
                      : ' job'
                  }}</span
                ></label
              >
            </li>
          </ul>
          <ButtonsSemanticButton
            v-model:pressed="buttons[0].active"
            class="btn--icon-dropdown-minimal filter-button block w-full justify-between"
            :text="buttons[1].name"
            :classes="'btn'"
            :class="{ active: buttons[1].active }"
            @click="onFilterPress($event, buttons[1].id, buttons[1].name)"
            @keydown="onFilterPress($event, buttons[1].id, buttons[1].name)"
          />
          <ul ref="filterListMenuModal" class="filter-container">
            <li v-for="(item, index) in filterList.locations" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
                ><input
                  :id="index"
                  v-model="filters.locations"
                  :value="index"
                  type="checkbox"
                  :name="index"
                  class="self-center"
                  @change="updateUrlParam"
                /><span>{{ index }}</span>
                <span class="ml-1/4 self-center text-xs italic text-blue"
                  >{{
                    jobListCalculations.locations[index]
                      ? '(' + jobListCalculations.locations[index]
                      : '(' + 0
                  }}{{
                    (jobListCalculations.locations[index] &&
                      jobListCalculations.locations[index] > 1) ||
                    !jobListCalculations.locations[index]
                      ? ' jobs)'
                      : ' job)'
                  }}</span
                ></label
              >
            </li>
          </ul>
          <ButtonsSemanticButton
            v-model:pressed="buttons[2].active"
            class="btn--icon-dropdown-minimal filter-button block w-full justify-between"
            :text="buttons[2].name"
            :classes="'btn'"
            :class="{ active: buttons[2].active }"
            @click="onFilterPress($event, buttons[2].id, buttons[2].name)"
            @keydown="onFilterPress($event, buttons[2].id, buttons[2].name)"
          />
          <ul ref="filterListMenuModal" class="filter-container">
            <li v-for="(item, index) in filterList.type" :key="index">
              <label
                class="filter-container-item justify-left flex flex-row pb-1/2 pl-1 pr-1 pt-1/4"
                :for="index"
                ><input
                  :id="index"
                  v-model="filters.type"
                  :value="index"
                  type="checkbox"
                  :name="index"
                  class="self-center"
                  @change="updateUrlParam"
                /><span>{{ index }}</span></label
              >
            </li>
          </ul>
        </div>
        <div class="filter-button--apply relative">
          <ButtonsSemanticButton
            v-if="device === 'm'"
            class="block"
            :text="filterModalButtonTextApply"
            :classes="'btn btn--blue mt-1 md:mt-0 justify-center'"
            @click="openModal = !openModal"
          />
        </div>
      </div>
    </ModalsTheModal>
  </div>
  <div v-else class="mb-2 flex w-full justify-center">
    <AnimationsLoadingCirclesInline />
  </div>
</template>

<script>
  export default {
    props: {
      textStrings: {
        type: Object,
        required: false,
      },
      department: {
        type: String,
        required: false,
        default: 'All',
      },
      offFilterBgClicked: {
        type: Boolean,
        required: false,
        default: false,
      },
      sourceParam: {
        type: String,
        required: false,
        default: '',
      },
    },
    async setup() {
      const { locale } = useI18n();
      const router = useRouter();
      const route = useRoute();
      const { analyticsTrack, analyticsTrackLink } = useAnalytics();

      const { data: rolesEnglish, pending: rolesEnglishPending } =
        await useFetch(
          'https://api.greenhouse.io/v1/boards/samsara/departments'
        );
      const { data: rolesDACH, pending: rolesDACHPending } = await useFetch(
        'https://api.greenhouse.io/v1/boards/samsaradach/departments'
      );
      const filterTools = ref(null);
      const filterListMenu = ref(null);
      const toolsSpacer = ref(null);
      const device = ref(null);
      const openModal = ref(false);
      const hasHandleBodyClickAdded = ref(false);

      return {
        hasHandleBodyClickAdded,
        rolesEnglish,
        rolesDACH,
        openModal,
        dataLoading: rolesEnglishPending || rolesDACHPending,
        locale,
        route,
        router,
        filterTools,
        filterListMenu,
        toolsSpacer,
        device,
        analyticsTrack,
        analyticsTrackLink,
      };
    },
    data() {
      return {
        rolesEn: '',
        ready: false,
        rolesDach: '',
        jobList: '',
        isSticky: false,
        searchJobList: '',
        searchInputValue: '',
        filterModalButtonTextTrigger: 'More Filters',
        filterModalButtonTextApply: 'Apply Filters',
        filterButtonTriggerClearAll: 'Clear All Filters',
        modalTextHeadline: 'Filter by',
        resultsNumInitialTotal: 0,
        resultsNumOfLocations: 0,
        resultsNumOfDepartments: 0,
        filterListMenuHeight: 0,
        filterListMenuOffsetLeft: null,
        numOfResults: null,
        searchResults: null,
        filterMenuLeft: null,
        clearedSearch: null,
        ghSrcString: 'gh_src',
        ghSrcValue: '',
        customSearchResponseIndexArr: [],
        departments: [],
        locationsRolesArr: [],
        locationsRolesCountArr: [],
        departmentsRolesCountArr: [],
        growthEngineeringRoles: [],
        growthEngineeringRoleCheck: [
          'Growth Engineer',
          'Senior Growth Engineer',
          'Senior Growth Engineer - Growth',
          'Senior Software Engineer - Growth',
          'Senior Software Engineer, Full Stack',
          'Manager, Growth Engineering',
          'Engineering Manager, Growth',
          'Engineering Manager, Full Stack',
          'Software Engineer - Growth',
        ],
        ukArray: [
          'London, UK',
          'Munich, Germany',
          'Paris, France',
          'Amsterdam, Netherlands',
          'Poland',
        ],
        filters: {
          departments: [],
          locations: [],
          type: [],
        },
        filterList: {
          departments: {},
          locations: {},
          type: { 'Full-Time': false, 'Fixed-Term': false, Intern: false },
        },
        locationsCounter: {},
        locations: [],
        activeFilter: '',
        buttons: [
          {
            name: this.textStrings.filters[0].toLowerCase(),
            active: false,
            id: 0,
            offsetLeft: null,
          },
          {
            name: this.textStrings.filters[1].toLowerCase(),
            active: false,
            id: 1,
            offsetLeft: null,
          },
          {
            name: this.textStrings.filters[2].toLowerCase(),
            active: false,
            id: 2,
            offsetLeft: null,
          },
        ],
        consolidated: '',
        activeFilterList: [],
        navHeight: 0,
        resultsFound: null,

        active: false,
        isSearchInputEmpty: true,
        searchActive: false,
        isClearing: false,
      };
    },
    fetchOnServer: false,
    computed: {
      jobListCalculations() {
        const counter = { departments: {}, locations: {} };
        for (let i = 0; i < this.jobList.length; i++) {
          counter.departments[this.jobList[i][0]] = this.jobList[i][1].length;
          this.jobList[i][1].forEach((job) => {
            if (counter.locations[job[2]]) {
              counter.locations[job[2]]++;
            } else {
              counter.locations[job[2]] = 1;
            }
          });
        }
        return counter;
      },
      isFilterActive() {
        if (
          this.filters.departments.length > 0 ||
          this.filters.locations.length > 0 ||
          this.filters.type.length > 0 ||
          this.searchInputValue.length > 0
        ) {
          return true;
        }
        return false;
      },
      returnActiveFilterList() {
        return this.filterList[this.activeFilter];
      },
      totalNumFilterTools() {
        return this.buttons.length;
      },
      totalNumOfLocations() {
        return Object.keys(this.filterList.locations).length;
      },
      totalNumOfDepartments() {
        return Object.keys(this.filterList.departments).length;
      },
      processSearchResults() {
        const jobDepartments = [];
        let jobs = [];
        if (this.searchResults !== '') {
          for (const value in this.searchResults) {
            if (
              !jobDepartments.includes(this.searchResults[value].department)
            ) {
              // create list of departments with roles
              jobDepartments.push(this.searchResults[value].department);
            }
          }
          jobs = jobDepartments.map((step) => {
            return [
              step,
              this.searchResults
                .map((job) => {
                  if (job.department === step) {
                    return [
                      job.title ? job.title : null,
                      job.url ? job.url : null,
                      job.location ? job.location : null,
                      job.type ? job.type : null,
                      job.id ? job.id : null,
                    ];
                  }
                })
                .filter((item) => {
                  // remove empty arrays
                  if (item !== undefined) {
                    return item;
                  }
                }),
            ];
          });
        } else {
          return [];
        }

        return jobs;
      },
      sortedLocations() {
        return this.$i18n.locale === 'uk'
          ? this.locations
          : this.locations.sort();
      },
      sortedDepartments() {
        return this.departments.sort();
      },
    },
    watch: {
      numOfResults() {
        if (this.numOfResults === 0) {
          this.$nextTick(() => {
            this.resultsFound = false;
          });
        }
      },
      isSearchInputEmpty() {
        if (this.isSearchInputEmpty || !this.searchActive) {
          this.setResultsCountData(this.jobList);
          this.$nextTick(() => {
            this.clearedSearch = false;
          });
        }
      },
    },
    created() {
      this.rolesEn = this.rolesEnglish;
      this.rolesDach = this.rolesDACH;
      // create department list, pass in the german deparments, and english departments
      this.createDepartmentsList(
        this.rolesDach.departments,
        this.rolesEn.departments
      );
      this.consolidated = this.rolesEn.departments;

      const externalCheck = [];
      // custom filter for external jobs
      this.consolidated.forEach((step) => {
        step.jobs.forEach((job) => {
          const metaObject = job.metadata.find((o) => o.id === 2601855);
          const externalName = metaObject.value;
          if (externalName !== null) {
            externalCheck.push(externalName);
          }
        });
      });
      const externalNameCheck = [...new Set(externalCheck)];
      const externalListing = [];

      const jobDepartment = this.consolidated.map((step) => {
        return [
          step.name,
          step.jobs
            .map((job) => {
              // check if external
              const metaObject = job.metadata.find((o) => o.id === 2601855);
              const metaType = job.metadata.find((o) => o.id === 110712);
              const isExternal = metaObject.value !== null;
              const externalValue = metaObject.value;
              if (isExternal) {
                externalListing.push([
                  job.title ? job.title : null,
                  job.id ? job.id : null,
                  job.location.name ? job.location.name : null,
                  metaType.value ? metaType.value : null,
                  externalValue,
                ]);
                return [];
              }
              // pull out GE roles
              else if (this.growthEngineeringRoleCheck.includes(job.title)) {
                this.growthEngineeringRoles.push([
                  job.title ? job.title : null,
                  job.id ? job.id : null,
                  job.location.name ? job.location.name : null,
                  metaType.value ? metaType.value : null,
                  step.name,
                ]);
                return [];
              } else {
                return [
                  job.title ? job.title : null,
                  job.id ? job.id : null,
                  job.location.name ? job.location.name : null,
                  metaType.value ? metaType.value : null,
                  step.name,
                  externalValue,
                ];
              }
            })
            .filter((item) => {
              // remove empty arrays
              return item.length;
            }),
        ];
      });
      // add external departments
      const externalDupFilter = externalNameCheck.filter(
        (name) => !jobDepartment.some((department) => name === department[0])
      );
      externalDupFilter.forEach((item) => {
        jobDepartment.push([item, []]);
      });
      // push GE roles to new department
      jobDepartment.forEach((item) => {
        if (item[0] === 'Software Engineering') {
          item[1].push(...this.growthEngineeringRoles);
        }
      });
      // push external roles to department
      externalListing.forEach((job) => {
        jobDepartment.forEach((department) => {
          if (department[0] === job[4]) {
            department[1].push(job);
          }
        });
      });
      let jobLocations = [];
      let totalCount = 0;
      this.consolidated.forEach((element, i) => {
        if (i != this.consolidated.length - 1) {
          this.consolidated[i].jobs.forEach((elementB) => {
            totalCount++;
            const jobLocation = elementB.location.name;
            if (!jobLocations.includes(jobLocation)) {
              jobLocations.push(jobLocation);
            }
          });
        }
      });
      if (this.locale === 'uk') {
        const resOther = jobLocations.filter(
          (item) => !this.ukArray.includes(item)
        );

        const newArray = [...this.ukArray, ...resOther];

        jobLocations = newArray;
      }
      const filteredDepartments = jobDepartment.filter(
        (item) => item[1].length != 0
      );
      filteredDepartments.sort();
      this.resultsNumInitialTotal = totalCount;
      this.numOfResults = totalCount;
      this.locations = jobLocations;
      this.departments = filteredDepartments;
      this.jobList = this.sortedDepartments;
      for (let i = 0; i < this.jobList.length; i++) {
        this.departmentsRolesCountArr.push([
          this.jobList[i][0],
          this.jobList[i][1].length,
        ]);
      }
      this.buildSearchIndex(this.jobList);
      this.setFilterOptionsList();
      this.resultsNumOfLocations = this.totalNumOfLocations;
      this.resultsNumOfDepartments = this.totalNumOfDepartments;
      this.resultsFound = true;
      this.setLocationsRolesCount();
      this.textStrings.filters.forEach((filterName) => {
        if (this.$route.query[filterName]) {
          this.handleRoutePush(filterName);
        }
      });
      this.returnFilteredResults();
      this.$emitter.on('nav-height', (height) => {
        this.navHeight = height;
      });

      this.ready = true;

      this.$emitter.on('updateParams', (value) => {
        // if there are query params in the URL already dont update the filters
        if (!this.route.query.departments && value.length > 0) {
          this.filters.departments = value;
          this.updateUrlParam();
        }
      });
    },
    mounted() {
      this.onWindowResize();
      window.addEventListener('resize', this.onWindowResize);
      window.addEventListener('scroll', this.onWindowScroll);
    },
    onUnmounted() {
      window.removeEventListener('resize', this.onWindowResize);
      window.removeEventListener('scroll', this.onWindowScroll);
    },
    methods: {
      handleRoutePush(value) {
        if (Array.isArray(this.$route.query[value])) {
          this.filters[value] = [...this.$route.query[value]];
        } else {
          this.filters[value].push(this.$route.query[value]);
        }
      },
      processFilterMessageResultsValue(department, location) {
        if (location !== null) {
          return this.resultsNumOfLocations <= 1
            ? location.slice(0, -1)
            : location;
        }
        if (department !== null) {
          return this.resultsNumOfDepartments <= 1
            ? department.slice(0, -1)
            : department;
        }
      },
      updateRoute() {
        if (this.searchInputValue !== '') {
          this.router.replace({
            path: this.route.path,
            query: {
              locations: this.filters.locations,
              departments: this.filters.departments,
              type: this.filters.type,
              search: this.searchInputValue,
            },
          });
        } else {
          this.router.replace({
            path: this.route.path,
            query: {
              locations: this.filters.locations,
              departments: this.filters.departments,
              type: this.filters.type,
            },
          });
        }
      },
      updateUrlParam() {
        if (
          this.filters.locations.length === 0 &&
          this.filters.departments.length === 0 &&
          this.filters.type.length === 0 &&
          this.searchTerm === ''
        ) {
          this.router.replace({ path: this.route.path });
        } else {
          this.updateRoute();
        }
        this.returnFilteredResults();
      },
      returnFilteredResults() {
        const jobsFiltered = this.sortedDepartments.map((department) => {
          const allDepartments = department[1];
          const newJobs = allDepartments.filter((job) => {
            if (
              (this.filters.locations.includes(job[2]) ||
                this.filters.locations.length === 0) &&
              (this.filters.departments.includes(job[4]) ||
                this.filters.departments.length === 0) &&
              (this.filters.type.includes(job[3]) ||
                this.filters.type.length === 0)
            ) {
              return job;
            }
          });
          const jobList =
            this.filters.locations.length === 0 &&
            this.filters.departments.length === 0 &&
            this.filters.type.length === 0
              ? allDepartments
              : newJobs;

          return [department[0], jobList];
        });
        if (jobsFiltered.length === 0) {
          this.jobList = this.departments;
        } else {
          this.jobList = jobsFiltered;
        }
        this.buildSearchIndex(this.jobList);

        if (!this.searchActive) {
          this.setResultsCountData(this.jobList);
        }
      },
      setLocationsRolesCount() {
        for (let i = 0; i < this.jobList.length; i++) {
          for (let j = 0; j < this.jobList[i][1].length; j++) {
            this.locationsRolesArr.push(this.jobList[i][1][j][2]);
          }
        }
        this.locationsRolesCountArr = this.locationsRolesArr.reduce(
          (acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1,
          }),
          {}
        );
      },
      roleCount(index) {
        for (let i = 0; i < this.departmentsRolesCountArr.length; i++) {
          if (index === this.departmentsRolesCountArr[i][0]) {
            return `(${
              this.departmentsRolesCountArr[i][1] > 1
                ? this.departmentsRolesCountArr[i][1] + ' jobs'
                : this.departmentsRolesCountArr[i][1] + ' job'
            })`;
          }
        }
      },
      locationsRoleCount(index) {
        for (const value in this.locationsRolesCountArr) {
          if (index === value) {
            return `(${
              this.locationsRolesCountArr[value] > 1
                ? this.locationsRolesCountArr[value] + ' jobs'
                : this.locationsRolesCountArr[value] + ' job'
            })`;
          }
        }
      },
      trackSearchTerm(term) {
        this.analyticsTrack('Search term', {
          searchTerm: term,
        });
      },
      trackRoleLinkClick(value) {
        this.analyticsTrack('Job Link Clicked', {
          job_link: value,
        });
      },
      handleBodyClick(event) {
        const filterContainer = document.querySelector('.filter-container');
        const shouldClose =
          filterContainer &&
          filterContainer.contains(event.target) === false &&
          event.target.tagName !== 'INPUT';

        if (shouldClose) {
          this.closeFilterOptions();
        }
      },
      closeFilterOptions() {
        this.buttons.forEach((button) => {
          button.active = false;
          this.active = button.active;
        });
      },
      setResultsCountData(list) {
        this.numOfResults = 0;
        const departmentsSet = new Set();
        const locationsSet = new Set();
        for (const value in list) {
          if (list[value][1].length > 0) {
            this.numOfResults += list[value][1].length;
            departmentsSet.add(list[value][0]);
            for (const subvalue in list[value][1]) {
              locationsSet.add(list[value][1][subvalue][2]);
            }
          }
        }
        this.resultsNumOfLocations = locationsSet.size;
        this.resultsNumOfDepartments = departmentsSet.size;
      },
      setFilterOptionsList() {
        for (const key in this.departments) {
          const setKey = this.departments[key][0];
          this.filterList.departments[setKey] = false;
        }

        for (const key in this.sortedLocations) {
          const setKey = this.sortedLocations[key];
          this.filterList.locations[setKey] = false;
          this.locationsCounter[setKey] = 0;
        }
      },
      clearAllFilters() {
        this.isClearing = true;
        this.router.replace({
          path: this.route.path,
        });
        setTimeout(() => {
          this.jobList = this.sortedDepartments;
          for (const key in this.filterList.departments) {
            this.filterList.departments[key] = false;
          }
          for (const key in this.filterList.locations) {
            this.filterList.locations[key] = false;
          }
          for (const key in this.filterList.type) {
            this.filterList.type[key] = false;
          }
          this.searchResults = '';
          this.clearedSearch = true;
          this.searchActive = false;
          this.resultsFound = true;
          this.numOfResults = this.resultsNumInitialTotal;
          this.resultsNumOfLocations = this.totalNumOfLocations;
          this.resultsNumOfDepartments = this.totalNumOfDepartments;
          this.filters.departments = [];
          this.filters.locations = [];
          this.filters.type = [];
          this.buildSearchIndex(this.jobList);
          this.isClearing = false;
        }, 350);
        this.closeFilterOptions();
      },
      onWindowScroll() {
        if (
          this.$refs.filterTools &&
          document.querySelector('.cn-custom-navigation-header')
        ) {
          const navTop = document.querySelector(
            '.cn-custom-navigation-header'
          ).offsetHeight;
          if (this.$refs.toolsSpacer.getBoundingClientRect().top < navTop) {
            this.isSticky = true;
            this.$refs.filterToolsOuter.style.top = `${navTop}px`;
          } else {
            this.isSticky = false;
            this.$refs.filterToolsOuter.style.top = '0px';
          }
        }
      },
      onWindowResize() {
        const widthThreshold = 640;
        this.active = false;
        this.device = window.innerWidth > 916 ? 'd' : 'm';
        this.closeFilterOptions();
        if (this.device === 'd') {
          this.$emit('filter-tool-active', this.active);
        }

        if (window.innerWidth > widthThreshold) {
          if (!this.hasHandleBodyClickAdded) {
            document.body.addEventListener('click', this.handleBodyClick, true);
            this.hasHandleBodyClickAdded = true;
          }
        } else if (
          window.innerWidth <= widthThreshold &&
          this.hasHandleBodyClickAdded
        ) {
          if (this.hasHandleBodyClickAdded) {
            document.body.removeEventListener(
              'click',
              this.handleBodyClick,
              true
            );
            this.hasHandleBodyClickAdded = false;
          }
        }
      },
      closeModal() {
        this.openModal = false;
      },
      showSearchResults(value, input, isInputEmpty) {
        this.searchInputValue = input;
        this.isSearchInputEmpty = isInputEmpty;

        if (/^ *$/.test(input)) {
          this.searchActive = false;
        } else {
          this.searchActive = true;
          if (value.length >= 1) {
            this.searchResults = value;
            this.resultsFound = true;

            this.trackSearchTerm(this.searchInputValue);
            this.setResultsCountData(this.processSearchResults);
          }
          if (value.length === 0 && value !== undefined) {
            this.resultsFound = false;
            this.searchResults = '';
          }
        }
        if (value === undefined) {
          this.resultsFound = true;
        }
        this.updateRoute();
      },
      buildSearchIndex(list) {
        const indexArr = [];
        for (const entry of list) {
          for (let i = 0; i < entry[1].length; i++) {
            indexArr.push({
              document: {
                id: i,
                department: entry[0],
                title: entry[1][i][0],
                location: entry[1][i][2],
                type: entry[1][i][3],
              },
              meta: {
                id: i,
                department: entry[0],
                title: entry[1][i][0],
                location: entry[1][i][2],
                type: entry[1][i][3],
                url: entry[1][i][1],
                job_id: entry[1][i][1],
              },
            });
          }
        }
        this.customSearchResponseIndexArr = indexArr;
      },
      createDepartmentsList(germanDepts, engDepts) {
        germanDepts.forEach((element) => {
          if (element.jobs.length > 0) {
            const departmentName = element.name;
            engDepts.forEach((elEn) => {
              if (elEn.name === departmentName) {
                elEn.jobs.push(element.jobs[0]);
              }
            });
          }
        });
      },
      setFilterListPosition(position, height) {
        if (this.device !== 'm') {
          this.filterListMenu.style.left = `${position}px`;
          this.filterListMenu.style.top = `${height}px`;
        }
      },
      onFilterPress(event, id, name) {
        this.activeFilter = name;
        if (this.filterTools) {
          this.setFilterListPosition(
            event.target.offsetLeft,
            this.filterTools.offsetHeight
          );
        }

        this.buttons.forEach((button) => {
          if (button.id === id) {
            button.active = !button.active;
            this.active = button.active;
          } else {
            button.active = false;
          }
        });
        if (this.device === 'd') {
          this.$emit('filter-tool-active', true);
        }
      },
      toggleFilterValue(value) {
        for (const targetValue in this.filterList[this.activeFilter]) {
          if (value === targetValue) {
            this.filterList[this.activeFilter][targetValue] =
              !this.filterList[this.activeFilter][targetValue];
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    .modal {
      &.careers-modal {
        .modal__inner {
          @apply h-full;
        }
      }
      .modal__inner {
        width: 100%;
        @screen md {
          width: 90%;
          height: 70%;
        }

        .filter-container {
          box-shadow: none;
          max-height: 200px;
          @screen md {
            height: 200px;
          }
        }

        .btn.active + ul {
          overflow-x: scroll;
          height: 200px;
          @screen md {
            height: 200px;
          }
        }

        .filter-button {
          @apply pl-0 text-base capitalize tracking-normal text-blue-dark;
        }

        .btn + ul {
          opacity: 1;
          height: 1px;
          border-radius: 0px;
          visibility: visible;
          border-bottom: 1px solid #999999;
        }
        .filter-button--apply {
          position: absolute;
          left: 50%;
          bottom: 10px;
          transform: translate(-50%, 0);
        }
      }
    }
  }
  :deep() {
    .search-input {
      width: 100%;

      input {
        width: 100%;
        height: 45px;
      }
      @screen md {
        width: auto;

        input {
          width: auto;
        }
      }
    }
  }

  .btn--icon-clear-all {
    &:hover {
      @apply text-blue-dark;
    }

    &.clearing {
      &::before {
        animation: 300ms spin infinite linear;
        transform-origin: center;
      }
    }
  }

  .filter {
    &-button {
      z-index: 2;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
    }
    &-container {
      background: white;
      max-height: 304px;
      overflow-y: auto;
      z-index: 1;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.13);
      min-width: auto;
      border-radius: theme('borderRadius.DEFAULT');
      &-item {
        &:hover {
          cursor: pointer;
        }
      }
      :deep() {
        i {
          @apply whitespace-nowrap text-sm text-blue-darker;
        }
      }
      input[type='checkbox'] {
        background-color: #fff;
        width: 1em;
        height: 1em;
        border: 1px solid theme('colors.blue.DEFAULT');
        margin-right: 5px;
        border-radius: theme('borderRadius.DEFAULT');
        transition:
          opacity 250ms ease 0s,
          box-shadow 250ms ease 0s;
        inset: 0px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .roles {
    &-filter {
      &--tools {
        transition: all 400ms ease;
        &-outer {
          &.absolute,
          &.fixed {
            z-index: 1;
          }
          &.absolute {
            left: 50%;
            transform: translate(-50%, 0);
          }
          &.fixed {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
          }
        }
        ul {
          opacity: 0;
          visibility: hidden;
          transform: translatex(2px);
          transition: all 400ms ease;
          li {
            label {
              border-radius: theme('borderRadius.DEFAULT');
              &:hover {
                cursor: pointer;
                background-color: theme('colors.blue.lighter');
              }
            }
          }
          &.active {
            opacity: 1;
            visibility: visible;
            transform: translatex(0px);
          }
        }
        .btn {
          &:active {
            transform: translateY(3px);
          }
          &.active {
            &.btn--icon-dropdown {
              &::after {
                transform: rotateX(360deg);
              }
            }
            &.btn--icon-dropdown-minimal {
              &::after {
                transform: rotateX(900deg);
              }
            }
          }
        }
      }
    }
  }
  .btn {
    display: flex;

    &-arrow {
      height: 0px;
      width: 0px;
      border: none;
      border-top: 5px solid #000000;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      transition: all 400ms ease;
    }
    &:hover {
      .btn-arrow {
        border: none;
        border-color: white;
        border-top: 5px solid white;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
      }
    }
  }

  .list {
    &--results {
      min-height: 500px;
      &-messaging {
        padding: 0 1.5em 0 1.5em;
      }
      &-item {
        list-style: none;
        &-inner {
          &:hover {
            background: #f6f6f6;
          }
        }
        &-department {
          @apply font-semibold text-blue;
          letter-spacing: 1px;
        }
        &-title {
          display: block;
          font-size: 20px;
          margin-bottom: -5px;
        }
        &-location {
          font-size: 16px;
        }
      }
    }
  }
  :deep() {
    .lunr-search {
      input {
        @apply rounded-full border-1 border-blue;
      }

      .lunr-result {
        @apply border-b-1 border-gray-lightest p-1/2;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
</style>
