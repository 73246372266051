<template>
  <span
    v-if="modules.customText[0].fields.content"
    v-html="modules.customText[0].fields.content"
  ></span>
</template>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>
