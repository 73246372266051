<template>
  <div ref="scrollWrap" class="w-full px-gap2 lg:container">
    <div class="sticky-wrapper -mx-gap flex flex-wrap">
      <div
        ref="stickyContent"
        class="column wrapper--sticky__content relative w-12/12 px-gap md:w-6/12"
      >
        <div ref="stickyContainer" class="container--sticky">
          <RichTextRenderer
            v-if="modules.content"
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
          <template v-for="(tab, index) in modules.tabs">
            <div class="mb-2 block md:hidden">
              <ImagesPicture :image="tab.fields.image.fields" />
            </div>
            <div
              :id="tab.fields.id"
              class="content mb-3 cursor-pointer md:mb-1 md:pr-4"
              :class="{ active: index == 0 }"
              @click.prevent="scroll(tab.fields.id)"
            >
              <RichTextRenderer
                v-if="tab.fields.tabContent"
                :document="tab.fields.tabContent"
                :node-renderers="nodeRenderers"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        class="column images--sticky hidden w-12/12 px-gap md:block md:w-6/12"
      >
        <div
          v-for="(tab, index) in modules.tabs"
          :data-id="tab.fields.id"
          class="sticky-image"
        >
          <ImagesPicture :image="tab.fields.image.fields" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    created() {
      if (process.browser) {
        this.handleDebouncedScroll = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
    },
    methods: {
      handleScroll(event) {
        const tabletScreen = 736;
        const topOffset = 60;
        const stickyHeight = this.$refs.stickyContainer.offsetHeight;
        const containerHeight = this.$refs.stickyContent.offsetHeight;
        const containerWidth = this.$refs.stickyContent.offsetWidth;
        const contentFromTop =
          this.$refs.stickyContent.getBoundingClientRect().top;
        const stickyContainer = this.$refs.stickyContainer;
        const contentFromTopAbs = Math.abs(contentFromTop);

        if (window.innerWidth >= tabletScreen) {
          if (
            contentFromTop <= topOffset &&
            containerHeight - stickyHeight >= contentFromTopAbs
          ) {
            stickyContainer.classList.add('active');
            stickyContainer.classList.remove('end');
            stickyContainer.style.width = containerWidth + 'px';
          } else if (contentFromTop >= 0) {
            stickyContainer.classList.remove('end');
            stickyContainer.classList.remove('active');
          } else if (containerHeight - stickyHeight <= contentFromTopAbs) {
            stickyContainer.classList.add('end');
          }

          document.querySelectorAll('.sticky-image').forEach(function (el) {
            const currentImgID = el.getAttribute('data-id');
            const currentContent = document.querySelector(`#${currentImgID}`);

            if (
              el.getBoundingClientRect().top <=
              currentContent.getBoundingClientRect().top
            ) {
              document
                .querySelectorAll('.content.active')[0]
                .classList.remove('active');
              currentContent.classList.add('active');
            }
          });
        }
      },
      scroll(id) {
        if (document.querySelector(`[data-id='${id}']`)) {
          document
            .querySelector(`[data-id='${id}']`)
            .scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.images--sticky) {
    img {
      @apply mb-4 mt-4;

      border-radius: 23px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .wrapper--sticky__content {
    .container--sticky {
      will-change: top, bottom, transform;
      @screen md {
        @apply absolute bottom-auto top-0 w-full;
      }

      &.active {
        @apply fixed bottom-auto;

        top: 60px;
      }

      &.end {
        @apply absolute bottom-0 top-auto;
      }
    }
  }

  .content {
    opacity: 0.3;
    transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media (max-width: theme('screens.md')) {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }
</style>
