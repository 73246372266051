<template>
  <div class="w-full px-gap2 lg:container">
    <div class="-mx-gap flex flex-wrap">
      <div
        class="svg--quote column w-2/12 px-gap md:w-1/12"
        :class="{ 'svg--quote-green': topFleets }"
      >
        <svg
          width="69"
          height="58"
          viewBox="0 0 69 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.6537 10.8571V0C10.2178 1.52382 0 12.1904 0 32V58H30.6537V27.7143H17.062C17.062 18 21.5925 12.3809 30.6537 10.8571ZM69 10.8571V0C48.5641 1.52382 38.3463 12.1904 38.3463 32V58H69V27.7143H55.4083C55.4083 18 59.9388 12.3809 69 10.8571Z"
          />
        </svg>
      </div>
      <div
        v-if="modules.content"
        class="text--quote column relative z-20 w-10/12 px-gap md:w-8/12"
      >
        <div class="text--quote__inner bg-blue-dark pl-1/2 md:bg-transparent">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
    <div class="wrapper--audio -mx-gap flex flex-wrap">
      <div class="column box--audio w-12/12 px-gap md:ml-5/12 md:w-7/12">
        <div
          class="wrapper__inner relative z-10 -mt-5 flex p-2"
          :class="{ 'wrapper__inner-blue': !topFleets }"
        >
          <div>
            <p class="audio__title mb-1/4 w-4/5 font-bold text-white">
              {{ modules.json.audio_title }}
            </p>
            <div
              id="audio-progress"
              ref="audioProgress"
              height="10"
              class="relative bg-white"
            >
              <span
                id="progress-bar"
                ref="progressBar"
                class="absolute left-0 top-0 w-0 bg-green"
                :class="{ 'bg-blue': !topFleets }"
              ></span>
              <div
                id="audio-play"
                ref="audioPlay"
                class="absolute cursor-pointer"
                @click="togglePlay"
              >
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30 0C13.4 0 0 13.4 0 30C0 46.6 13.4 60 30 60C46.6 60 60 46.6 60 30C60 13.4 46.6 0 30 0Z"
                    :class="`${
                      topFleets ? 'audio-play__green' : 'audio-play__blue'
                    }`"
                  />
                  <path
                    d="M25.1779 39.863V20.137L40.8492 30L25.1779 39.863Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <audio id="audio-file" ref="audio" @timeupdate="updateBar">
              <source
                :src="
                  modules.media[0].fields.video.fields.file
                    ? modules.media[0].fields.video.fields.file.url
                    : ''
                "
                type="audio/mpeg"
              />
            </audio>
            <span
              id="current-time"
              ref="timeCounter"
              class="p--xs-eyebrow font-normal text-white"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
    data() {
      return {
        audioFile: '',
        canvasWidth: '',
        audioProgress: '',
        currentTime: '',
        topFleets: true,
      };
    },
    mounted() {
      this.canvasWidth = this.$refs.audioProgress.offsetWidth;
      this.audioFile = this.$refs.audio;
      this.audioProgress = this.$refs.progressBar;
      this.topFleets = this.modules.json.top_fleets !== false;
      this.$refs.timeCounter.innerHTML = this.convertElapsedTime(
        this.getCurrentTime()
      );

      window.togglePlay = this.togglePlay;
      window.updateBar = this.updateBar;
    },
    methods: {
      togglePlay: function () {
        return this.audioFile.paused
          ? this.audioFile.play()
          : this.audioFile.pause();
      },
      updateBar: function () {
        const duration = this.audioFile.duration;

        this.$refs.timeCounter.innerHTML = this.convertElapsedTime(
          this.getCurrentTime()
        );

        const percentage = this.getCurrentTime() / duration;
        const progress = this.canvasWidth * percentage;
        this.audioProgress.style.width = progress + 'px';
      },
      convertElapsedTime: function (inputSeconds) {
        let seconds = Math.floor(inputSeconds % 60);
        if (seconds < 10) {
          seconds = '0' + seconds.toString();
        }

        const minutes = Math.floor(inputSeconds / 60);
        return minutes + ':' + seconds.toString();
      },
      getCurrentTime: function () {
        return this.audioFile.currentTime;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #audio-progress {
    width: calc(100% - 60px);
    height: 7px;
  }

  .svg--quote {
    svg {
      fill: theme('colors.blue.DEFAULT');
    }

    &.svg--quote-green {
      svg {
        fill: theme('colors.green');
      }
    }
  }

  .audio-play__green {
    fill: theme('colors.green');
  }

  .audio-play__blue {
    fill: theme('colors.blue.DEFAULT');
    &:hover {
      fill: theme('colors.blue.darker');
    }
  }

  #progress-bar {
    height: 7px;
  }

  #audio-play {
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);

    svg {
      margin-left: -1px;
      box-shadow: 4px 4px 19px rgba(0, 0, 0, 0.35);
      border-radius: 100%;
    }
  }

  .quote--text__inner {
    margin-right: 6px;
  }

  :deep(.text--quote__inner) {
    .p--large {
      @screen md {
        @apply bg-blue-dark;
      }
    }

    .p--fine-print {
      @screen md {
        max-width: 50%;
      }
    }
  }

  .wrapper__inner {
    border: 6px solid theme('colors.green');

    &.wrapper__inner-blue {
      border: 6px solid theme('colors.blue.DEFAULT');
    }

    & > div:first-child {
      flex-grow: 1;
    }

    .audio__title {
      @screen md {
        width: 88%;
        font-size: 18px;
      }
    }

    @media (max-width: theme('screens.md')) {
      min-height: 275px;
      margin-top: -150px;

      & > div:first-child {
        margin-top: auto;
      }
    }
  }
</style>
