<template>
  <div id="index-solution-carousel">
    <div class="w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap justify-center pb-2 md:pb-0">
        <div
          v-if="modules.content"
          class="column w-11/12 px-gap text-center md:w-8/12"
        >
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
    <div class="w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap">
        <div
          class="column rotator--mobile-image owl-carousel block w-12/12 px-gap md:hidden"
        >
          <Swiper
            v-if="modules.slides"
            v-bind="indexMobileOptions"
            :modules="[SwiperNavigation]"
            @swiper="onMobileSwiper"
          >
            <SwiperSlide v-for="(slide, index) in modules.slides" :key="index">
              <div class="carousel__slide">
                <ImagesPicture
                  v-if="slide.fields.image.sys.contentType.sys.id === 'image'"
                  :image="slide.fields.image.fields"
                />
                <VideosTheVideo v-else :video="slide.fields.image.fields" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          class="wrapper--dots relative w-full overflow-x-scroll md:overflow-x-visible"
        >
          <div
            class="labels--index mt-1 flex w-full flex-row flex-nowrap items-center justify-around md:mt-2"
          >
            <div
              v-for="(slide, index) in modules.slides"
              class="label relative flex w-full flex-grow cursor-pointer flex-col self-stretch"
              :class="{ active: index == selectedIndex }"
              @click="goToSlide(index)"
            >
              <h3 v-if="slide.fields.label">{{ slide.fields.label }}</h3>
              <span
                :class="{
                  'active showProgress': index == selectedIndex,
                  pauseProgress: index == clickIndex,
                }"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel--index-solution mt-2 md:mt-4">
        <Swiper
          v-if="modules.slides"
          v-bind="indexOptions"
          :modules="[SwiperNavigation, SwiperController]"
          :controller="{ control: mobileSwiperRef }"
          @swiper="onSwiper"
          @slide-change="updateSlides()"
        >
          <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-bullets"
          ></div>
          <SwiperSlide v-for="(slide, index) in modules.slides" :key="index">
            <div
              class="index-rotator-slide -mx-gap flex flex-wrap"
              data-dot="<h3>{0}</h3><span></span>"
            >
              <div
                v-if="slide.fields.content"
                class="column wrapper__content w-12/12 px-gap md:w-6/12"
              >
                <RichTextRenderer
                  :document="slide.fields.content"
                  :node-renderers="nodeRenderers"
                />
              </div>
              <div
                class="w/12-12 column image-wrap hidden px-gap md:block md:w-6/12"
              >
                <ImagesPicture
                  v-if="slide.fields.image.sys.contentType.sys.id === 'image'"
                  :image="slide.fields.image.fields"
                />
                <VideosTheVideo v-else :video="slide.fields.image.fields" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },

    props: ['modules'],
    setup() {
      const mobileSwiperRef = ref();
      const swiperRef = ref();

      const onMobileSwiper = (swiper) => {
        mobileSwiperRef.value = swiper;
      };
      const onSwiper = (swiper) => {
        swiperRef.value = swiper;
      };
      return {
        mobileSwiperRef,
        onMobileSwiper,
        swiperRef,
        onSwiper,
      };
    },
    data() {
      return {
        selectedIndex: 0,
        clickIndex: null,
        indexOptions: {
          loop: false,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
          autoplay: {
            delay: 10000,
            disableOnInteraction: true,
          },
        },
        indexMobileOptions: {
          loop: false,
          navigation: {
            nextEl: '.next--arrow',
            prevEl: '.prev--arrow',
          },
          lazy: {
            loadPrevNext: true,
          },
          slidesPerView: 1,
          allowTouchMove: false,
        },
      };
    },
    methods: {
      goToSlide(index) {
        this.clickIndex = index;
        this.swiperRef.slideTo(index);
      },
      updateSlides() {
        this.selectedIndex = this.swiperRef.activeIndex;

        const currentLabel =
          document.querySelectorAll('.label')[this.selectedIndex];
        const wrapper = document.querySelector('.wrapper--dots');
        const labelScrollDistance = currentLabel.getBoundingClientRect().left;
        const currentScrollPosition = wrapper.scrollLeft;
        const offsetLeft = wrapper.getBoundingClientRect().left;

        document.querySelector('.wrapper--dots').scrollLeft =
          currentScrollPosition + labelScrollDistance - offsetLeft;
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.wrapper__content) {
    .subhead {
      @screen md {
        font-size: 22px;
        line-height: 34px;
      }
    }

    @screen md {
      padding-right: 4.1667%;
    }
  }

  .labels--index {
    @media (max-width: theme('screens.md')) {
      overflow-x: scroll;
      min-width: 1080px;
    }

    .label {
      margin: 0 2px;

      h3 {
        margin-bottom: 10px;
        color: rgba(#00263e, 0.45);
        transition: 0.1s ease-in-out;
        font-weight: normal;
        margin-top: auto;

        @screen md {
          font-size: 17px;
          line-height: 26px;
        }
      }

      &:hover {
        h3 {
          font-weight: normal;
          color: theme('colors.blue.dark');
        }
      }

      &.active {
        h3 {
          color: theme('colors.blue.dark');
          font-weight: 500;
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(#00263e, 0.06);
      }

      span {
        display: block;
        background-color: theme('colors.blue.DEFAULT');
        height: 5px;
        width: 0;

        &.pauseProgress {
          width: 100%;
          animation: none;
        }
      }
    }
  }

  .rotator--mobile-image {
    touch-action: manipulation;
  }

  .showProgress {
    animation-name: progress;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
</style>
