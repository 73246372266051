<template>
  <div class="w-full">
    <FiltersCareersRolesSearch
      :text-strings="textStrings"
      :source-param="urlParam"
    />
  </div>
</template>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        urlParam: '',
      };
    },
    computed: {
      textStrings() {
        return {
          role: 'roles',
          filters: ['departments', 'locations', 'type'],
          resultsFoundMsg: 'Hoot! We found: ',
          noResultsFoundMsg:
            'Uh oh, no open roles seem to match. Try removing filters or changing your search terms.',
          conjunctionValueOne: 'and',
          conjunctionValueTwo: 'in',
        };
      },
    },
    mounted() {
      this.getSourceParam();
    },
    methods: {
      getSourceParam() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const param = urlParams.get('gh_src');

        this.urlParam = param !== null ? `&gh_src=${param}` : '';
      },
    },
  };
</script>
