<template>
  <div class="widget--leadership mb-4 w-1/2 text-center md:w-1/4 md:px-2">
    <div @click.prevent="openModal = !openModal">
      <ImagesNuxtImg
        v-if="props.person.fields?.leaderImage?.fields?.file"
        classes="max-w-full rounded-full p-1/2 md:p-0"
        :url="props.person.fields.leaderImage.fields.file.url"
        :alt="props.person.fields.leaderImage.fields.description"
        width="150"
        height="150"
      />
      <p class="mb-1/2 text-lg font-medium text-blue-dark md:pt-1">
        {{ props.person.fields.name }}
      </p>
      <p class="widget--leadership__title">{{ props.person.fields.title }}</p>
    </div>
    <ModalsTheModal bg="bg-white" :is-open="openModal" @close="closeModal">
      <div class="-mx-gap flex flex-wrap p-4">
        <div class="w-12/12 md:w-2/12">
          <ImagesNuxtImg
            v-if="props.person.fields?.leaderImage?.fields?.file"
            classes="max-w-full rounded-full p-1/2 md:p-0"
            :url="props.person.fields.leaderImage.fields.file.url"
            :alt="props.person.fields.leaderImage.fields.description"
          />
        </div>
        <div
          class="flex w-12/12 flex-col justify-center text-left md:w-8/12 md:pl-2"
        >
          <h2 class="mb-0 text-blue-dark">
            {{ props.person.fields.name }}
          </h2>
          <p class="widget--leadership__title">
            {{ props.person.fields.title }}
          </p>
        </div>
        <div class="mt-2 w-12/12 text-left">
          <MarkdownTheMarkdown
            v-if="props.person.fields.bio"
            :source="props.person.fields.bio"
          />
        </div>
      </div>
    </ModalsTheModal>
  </div>
</template>

<script setup>
  const props = defineProps({
    person: {
      type: Object,
      required: true,
    },
  });

  const openModal = ref(false);

  const closeModal = () => {
    openModal.value = false;
  };
</script>

<style lang="scss" scoped>
  .widget--leadership {
    img {
      @apply inline;

      @screen md {
        max-width: 180px;
      }
    }
  }
  .widget--leadership__title {
    font-size: 15px;
    color: #747476;
  }
</style>
