<template>
  <section id="solutions-benefits">
    <div class="w-full px-gap2 lg:container">
      <div class="-mx-gap flex flex-wrap items-center">
        <div
          class="column solutions-benefits__content flex w-12/12 flex-col items-start justify-center text-left md:w-5/12"
        >
          <RichTextRenderer
            v-if="modules.content"
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </div>
        <div class="column w-12/12 md:w-7/12">
          <div
            v-if="modules.tabs"
            class="solutions-benefits__tabs flex flex-wrap"
          >
            <template v-for="(tab, index) in modules.tabs" :key="index">
              <LinksLocaleLink
                :link="getNestedProperty(tab, 'fields.label', false)"
                class="solutions-benefits__tab center relative flex w-6/12 flex-wrap items-center justify-between no-underline"
              >
                <RichTextRenderer
                  :document="getNestedProperty(tab, 'fields.tabContent', false)"
                  :node-renderers="nodeRenderers" />
                <ImagesPicture
                  v-if="getNestedProperty(tab, 'fields.image.fields', false)"
                  :image="getNestedProperty(tab, 'fields.image.fields', false)"
                  :lazy="false"
                  class="icon-dark absolute right-[27px]"
              /></LinksLocaleLink>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { getNestedProperty } from '~/utils';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: ['modules'],
  };
</script>

<style lang="scss" scoped>
  #solutions-benefits {
    .solutions-benefits__content {
      padding: 0 11px;

      &::before {
        @apply absolute self-center;

        content: '';
        background-color: #f0f6fe;
        margin-right: -60px;
        z-index: -1;
        width: 576px;
        height: 340px;

        @media (max-width: theme('screens.md')) {
          margin-right: 0;
          width: 100%;
        }
      }
    }

    :deep(.solutions-benefits__tabs) {
      max-height: 400px;
      width: 600px;
      border: 0.5px #0384fb solid;

      .solutions-benefits__tab {
        height: 95px;
        border: 0.5px #0384fb solid;
        padding: 27px;

        .icon-dark {
          @apply hidden;
        }

        &:hover {
          .icon-dark {
            @apply block;
          }
        }

        picture {
          @apply flex-1 text-right;

          height: 33px;
          width: 33px;
        }

        picture:nth-of-type(2) {
          @apply hidden;
        }

        &:hover,
        &:focus,
        &:active {
          @apply bg-blue text-white;

          h5,
          img {
            @apply text-white;
          }

          picture:nth-of-type(2) {
            @apply block;
          }

          picture:nth-of-type(1) {
            @apply hidden;
          }
        }

        h5 {
          @apply mb-0 flex;

          line-height: 20px;
          max-width: 50px;
        }

        p {
          @apply hidden;
        }
      }

      @media (max-width: theme('screens.md')) {
        @apply w-12/12;

        max-height: 1000px;

        .solutions-benefits__tab {
          @apply w-12/12;
        }
      }
    }
  }
</style>
