<template>
  <div
    class="modal-slide relative m-1/2 block cursor-pointer"
    :class="{ 'content__slide-d-hover': desktopHover }"
    @click="
      isDesktop && type === 'card'
        ? null
        : $emit('openModal', slide.fields.link ? slide.fields.link : index)
    "
  >
    <span class="open-icon absolute duration-200 ease-in-out">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M10.5 3.68018H1.6C1.30826 3.68018 1.02847 3.79607 0.822183 4.00236C0.615893 4.20865 0.5 4.48844 0.5 4.78018V18.5802C0.5 18.8719 0.615893 19.1517 0.822183 19.358C1.02847 19.5643 1.30826 19.6802 1.6 19.6802H15.4C15.6917 19.6802 15.9715 19.5643 16.1778 19.358C16.3841 19.1517 16.5 18.8719 16.5 18.5802V9.68018"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M19.6798 6.92V0.5H13.2598"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M8.5 11.68L19.68 0.5"
            stroke="white"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20.18" height="20.18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
    <slot name="image">
      <ImagesPicture
        :image="slide.fields.image.fields"
        classes="w-full block"
        :lazy="true"
      />
    </slot>
    <div
      class="content__slide opacity-1 absolute bottom-0 left-0 z-[2] w-full p-1 duration-200 ease-in-out"
    >
      <slot>
        <h2 class="counter mb-0 text-white">0{{ index + 1 }}</h2>
        <span class="divide-line inline-block w-full bg-white"></span>
        <h3 class="text-white">{{ slide.fields.label }}</h3>
      </slot>
    </div>
    <div
      v-if="desktopHover"
      class="content__desktop text--all-white absolute left-0 top-0 z-[1] flex h-full w-full cursor-default flex-wrap items-center justify-center bg-black bg-opacity-75 p-1 text-center opacity-0 transition-opacity duration-100 ease-in-out"
    >
      <slot name="desktopContent"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      slide: {
        type: Object,
        required: true,
      },
      index: {
        type: [String, Number],
        required: false,
        default: '0',
      },
      desktopHover: {
        type: Boolean,
        required: false,
        default: false,
      },
      type: {
        type: String,
        required: false,
        default: 'slider',
      },
    },
    emits: ['openModal'],
    data() {
      return {
        isDesktop: true,
      };
    },
    created() {
      if (process.browser) {
        window.addEventListener('resize', this.desktopQuery);
      }
    },
    beforeUnmount() {
      if (process.browser) {
        window.removeEventListener('resize', this.desktopQuery);
      }
    },
    methods: {
      desktopQuery() {
        this.isDesktop = window.matchMedia('(min-width: 736px)').matches;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-slide {
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);

    .divide-line {
      height: 1px;
    }

    &::after {
      @apply absolute bottom-0 left-0 h-full  w-full transition-opacity duration-100 ease-in-out;

      content: '';
      background: linear-gradient(
        359.25deg,
        #000000 10.72%,
        rgba(0, 38, 62, 0) 91.06%
      );
    }

    .open-icon {
      top: 20px;
      right: 15px;
    }

    &:hover {
      .open-icon {
        @apply opacity-100;
      }

      .content__slide {
        @apply opacity-100;
      }
    }
  }
  @screen md {
    .content__slide-d-hover {
      &:hover {
        .content__desktop {
          @apply opacity-100;
        }

        &::after {
          @apply opacity-0;
        }
      }
    }
  }
  .content__slide-d-hover {
    @media (max-width: theme('screens.md')) {
      @apply h-auto w-full overflow-hidden;

      margin: 10px 0;

      img {
        position: relative;
      }
    }
  }
</style>
