<template>
  <component :is="tag" ref="lunr" class="lunr-search">
    <input
      :id="`lunr-search${elementId}`"
      ref="searchInput"
      :value="searchTerm"
      type="text"
      class="lunr-input"
      :placeholder="placeholder"
      aria-label="Search"
      autocomplete="off"
      spellcheck="false"
      pattern="^[^ ].+[^ ]$"
      @input="debounceSearch"
    />
  </component>
</template>

<script>
  import debounce from 'lodash.debounce';
  import lunr from 'lunr';

  import lunrStemmer from 'lunr-languages/lunr.stemmer.support';

  !lunr.stemmerSupport && lunrStemmer(lunr);

  const normalizeLanguage = (locale) =>
    (locale || '').substr(0, 2).toLowerCase();

  export default {
    props: {
      id: {
        type: String,
        default: '',
      },
      tag: {
        type: String,
        default: 'div',
      },
      placeholder: {
        type: String,
        default: 'Search',
      },
      cleared: {
        type: Boolean,
        required: false,
        default: null,
      },
      customSearchIndexArr: {
        type: Array,
        default: null,
      },
      lang: {
        type: String,
        default: '',
        validator: (val) => ['', 'en'].includes(val),
      },
      locale: {
        type: String,
        default: '',
        validator: (val) => ['', 'en'].includes(normalizeLanguage(val)),
      },
    },
    emits: ['search-results'],
    setup() {
      const route = useRoute();
      return { route };
    },
    data() {
      return {
        placeholderText: '',
        searchMeta: undefined,
        searchTerm: '',
        searchInputMethod: null,
        isInputEmpty: true,
        searchResults: [],
        customIndex: [],
        customSearchIndex: null,
        searchIndex: null,
        resultsVisible: false,
        finalCustomArray: this.customSearchIndexArr,
      };
    },
    computed: {
      elementId() {
        return this.id ? `-${this.id}` : '';
      },
    },
    watch: {
      cleared(val) {
        if (val === true) {
          this.$nextTick(() => {
            this.searchTerm = '';
            this.searchResults = this.searchData(this.searchTerm);

            this.isInputEmpty = this.searchTerm.length === 0;

            this.$emit(
              'search-results',
              this.searchResults,
              this.searchTerm,
              this.isInputEmpty
            );
          });
        }
      },
      customSearchIndexArr() {
        this.processCustomIndex();
        this.buildSearchIndex();
        this.searchResults = this.searchData(this.searchTerm);
        this.$emit(
          'search-results',
          this.searchResults,
          this.searchTerm,
          this.isInputEmpty
        );
      },
      searchInputMethod(val) {
        this.searchTerm = val;

        this.searchResults = this.searchData(val);

        this.isInputEmpty = val.length === 0;

        this.$emit(
          'search-results',
          this.searchResults,
          val,
          this.isInputEmpty
        );
      },
      placeholder(val) {
        this.setPlaceholderText(val);
      },
    },
    created() {
      this.setPlaceholderText();
    },
    mounted() {
      this.processCustomIndex();
      this.buildSearchIndex();
      if (this.route.query.search) {
        this.searchResults = this.searchData(this.route.query.search);
        this.searchTerm = this.route.query.search;

        this.isInputEmpty = this.route.query.search.length === 0;
        this.$emit(
          'search-results',
          this.searchResults,
          this.route.query.search,
          this.isInputEmpty
        );
      }
    },
    methods: {
      debounceSearch: debounce(function (e) {
        this.searchTerm = e.target.value;
        this.searchResults = this.searchData(e.target.value);

        this.isInputEmpty = e.target.value.length === 0;

        this.$emit(
          'search-results',
          this.searchResults,
          e.target.value,
          this.isInputEmpty
        );
      }, 300),
      fuzzySearchIntensity(term) {
        return term.length > 5 ? '~1' : '';
      },
      searchData(query) {
        if (query !== '') {
          const result = this.customSearchIndex.search(
            query + this.fuzzySearchIntensity(query)
          );
          if (result.length === 0) {
            return result;
          } else {
            return result.map((item) => {
              return this.customIndex.find((job) => {
                return item.ref === job.url.toString();
              });
            });
          }
        }
      },
      buildSearchIndex() {
        const documents = this.customIndex;
        let newIndex = null;

        newIndex = lunr(function () {
          this.ref('url');
          this.field('department');
          this.field('title');
          this.field('type');
          this.field('location');

          for (let i = 0; i < documents.length; i++) {
            this.add(documents[i]);
          }
        });

        this.customSearchIndex = newIndex;
      },
      processCustomIndex() {
        const newArr = [];
        for (let i = 0; i < this.customSearchIndexArr.length; i++) {
          newArr.push(this.customSearchIndexArr[i].meta);
        }
        this.customIndex = newArr;
      },
      setPlaceholderText(text) {
        if (text) {
          this.placeholderText = text;
        }
        const translationKey = 'lunr-module.placeholderText';
        const hasTranslation = this.$te(translationKey);
        if (hasTranslation) {
          this.placeholderText = this.$t(translationKey);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .lunr-search {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    ::placeholder {
      color: theme('colors.gray.dark');
    }
  }

  .lunr-input {
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 5px;
    line-height: 2rem;
    padding: 0 0.5em 0 2em;
    outline: none;
    transition: all 0.2s ease;
    background: #fff
      url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3e%3ccircle cx='6' cy='6' r='5' stroke='%230384FB' stroke-width='2'/%3e%3cpath d='M9.5 10.5L14.5 15.5' stroke='%230384FB' stroke-width='2'/%3e%3c/svg%3e")
      0.6em 0.5em no-repeat;
    background-size: 1rem;
    background-position: 10px 50%;
  }

  .lunr-input:focus {
    border-color: #ddd;
  }

  .lunr-results {
    display: block;
    position: absolute;
    width: 500px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 0.5rem 0 0;
    padding: 0.3rem;
    list-style-type: none;
    font-size: 1.1em;
    z-index: 1;
  }

  .lunr-result {
    cursor: pointer;
    line-height: 1.6em;

    .middot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: black;
      margin: 0 0.2em;
    }

    &--department,
    &--title,
    &--type,
    &--location {
      margin-bottom: 0;
    }
    &--type,
    &--location,
    &--department {
      font-size: 14px;
    }

    &--department {
      color: theme('colors.blue.darker');
    }
  }

  .lunr-result:hover,
  .lunr-result:focus {
    background-color: #eee;
  }

  .lunr-result a {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .lunr-result .text-right {
    position: absolute;
    right: 0;
  }

  .lunr-status {
    font-style: italic;
  }
</style>
