<template>
  <nav
    v-show="header && isMobile && $i18n.locale === 'en-US'"
    id="subnav--main"
    class="nav open"
    @click="toggleMenu($event)"
    @keydown.enter="toggleMenu($event)"
  >
    <div class="wrapper__subnav relative">
      <div class="subnav-links relative">
        <div
          v-if="header.fleetNavigation && header.fleetNavigation.products_main"
          class="flex flex-col"
        >
          <span
            :ref="header.fleetNavigation.products_main.title.toLowerCase()"
            class="subnav-link-title relative flex flex-row justify-between"
            :data-title="
              header.fleetNavigation.products_main.title.toLowerCase()
            "
          >
            <h4
              class="pointer-events-none mb-0 py-1 pl-[16px] pr-[16px] text-[24px]"
            >
              {{ header.fleetNavigation.products_main.title || '' }}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              class="subnav-icon pointer-events-none mr-1 self-center"
            >
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M16 1.49997L9 8.49994L2 1.49997"
                  stroke="#D9D9D9"
                  stroke-width="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect
                    width="10"
                    height="17"
                    fill="white"
                    transform="translate(17.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div
            class="wrapper__nav-drawer block w-full overflow-hidden bg-white pl-[22px] pr-[22px] duration-100 ease-in-out"
            :class="[
              {
                'open fade-in':
                  isActive ===
                  header.fleetNavigation.products_main.title.toLowerCase(),
              },
            ]"
          >
            <div
              v-if="
                header.fleetNavigation && header.fleetNavigation.products_main
              "
              :id="
                header.fleetNavigation.products_main.title.toLowerCase() || ''
              "
              class="drawer duration-100 ease-in-out md:pb-0"
              :class="{
                active:
                  isActive ===
                  header.fleetNavigation.products_main.title.toLowerCase(),
              }"
            >
              <div class="-mx-gap flex flex-wrap">
                <nav-column
                  v-if="header.fleetNavigation.products_links"
                  :content="header.fleetNavigation.products_main"
                  :config="header.viewMoreConfig.explore_products"
                  class="w-12/12 md:w-7/12"
                  is-toggle="true"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.fleetNavigation.products_title }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.fleetNavigation
                      .products_links"
                    :key="index"
                    :content="link"
                    image="icon"
                    type="half"
                  />
                </nav-column>
                <nav-column
                  v-if="header.fleetNavigation.integrations_links"
                  :content="header.fleetNavigation.products_main"
                  class="border__left w-12/12 md:w-5/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.fleetNavigation.integrations_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.fleetNavigation
                      .integrations_links"
                    :key="index"
                    :content="link"
                    image="icon"
                    type="full"
                  />
                </nav-column>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            header.industrialNavigation &&
            header.industrialNavigation.solutions_main
          "
          class="flex flex-col"
        >
          <span
            :ref="
              header.industrialNavigation.solutions_main.title.toLowerCase()
            "
            class="subnav-link-title relative flex flex-row justify-between"
            :data-title="
              header.industrialNavigation.solutions_main.title.toLowerCase()
            "
          >
            <h4
              class="pointer-events-none mb-0 py-1 pl-[16px] pr-[16px] text-[24px]"
            >
              {{ header.industrialNavigation.solutions_main.title || '' }}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              class="subnav-icon pointer-events-none mr-1 self-center"
            >
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M16 1.49997L9 8.49994L2 1.49997"
                  stroke="#D9D9D9"
                  stroke-width="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect
                    width="10"
                    height="17"
                    fill="white"
                    transform="translate(17.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div
            class="wrapper__nav-drawer w-full overflow-hidden bg-white pl-[22px] pr-[22px] duration-100 ease-in-out"
            :class="[
              {
                'open fade-in':
                  isActive ===
                  header.industrialNavigation.solutions_main.title.toLowerCase(),
              },
            ]"
          >
            <div
              v-if="
                header.industrialNavigation &&
                header.industrialNavigation.solutions_main
              "
              :id="
                header.industrialNavigation.solutions_main.title.toLowerCase() ||
                ''
              "
              class="drawer duration-100 ease-in-out md:pb-0"
              :class="{
                active:
                  isActive ===
                  header.industrialNavigation.solutions_main.title.toLowerCase(),
              }"
            >
              <div class="-mx-gap flex flex-wrap">
                <nav-column
                  v-if="header.industrialNavigation.solutions_main"
                  :content="header.industrialNavigation.solutions_main"
                  :config="header.viewMoreConfig.explore_solutions"
                  class="w-12/12 md:w-7/12"
                  is-toggle="true"
                >
                  <template #header-text>
                    <h5
                      v-if="header.industrialNavigation.business_links"
                      class="p--stat-text"
                    >
                      {{ header.industrialNavigation.business_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.industrialNavigation
                      .business_links"
                    :key="index"
                    :content="link"
                    image="icon"
                    type="half"
                  />
                </nav-column>
                <nav-column
                  v-if="header.industrialNavigation.industry_links"
                  :config="header.viewMoreConfig.explore_industries"
                  class="border__left w-12/12 md:w-5/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.industrialNavigation.industry_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.industrialNavigation
                      .industry_links"
                    :key="index"
                    :content="link"
                    image="icon"
                    type="no-desc"
                  />
                </nav-column>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            header.customersNavigation &&
            header.customersNavigation.customers_main
          "
          class="flex flex-col"
        >
          <span
            :ref="header.customersNavigation.customers_main.title.toLowerCase()"
            class="subnav-link-title relative flex flex-row justify-between"
            :data-title="
              header.customersNavigation.customers_main.title.toLowerCase()
            "
          >
            <h4
              class="pointer-events-none mb-0 py-1 pl-[16px] pr-[16px] text-[24px]"
            >
              {{ header.customersNavigation.customers_main.title || '' }}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              class="subnav-icon pointer-events-none mr-1 self-center"
            >
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M16 1.49997L9 8.49994L2 1.49997"
                  stroke="#D9D9D9"
                  stroke-width="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect
                    width="10"
                    height="17"
                    fill="white"
                    transform="translate(17.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div
            class="wrapper__nav-drawer w-full overflow-hidden bg-white pl-[22px] pr-[22px] duration-100 ease-in-out"
            :class="[
              {
                'open fade-in':
                  isActive ===
                  header.customersNavigation.customers_main.title.toLowerCase(),
              },
            ]"
          >
            <div
              v-if="
                header.customersNavigation &&
                header.customersNavigation.customers_main
              "
              :id="
                header.customersNavigation.customers_main.title.toLowerCase() ||
                ''
              "
              class="drawer duration-100 ease-in-out md:pb-0"
              :class="{
                active:
                  isActive ===
                  header.customersNavigation.customers_main.title.toLowerCase(),
              }"
            >
              <div class="-mx-gap flex flex-wrap">
                <nav-column
                  v-if="header.customersNavigation.customers_main"
                  :content="header.customersNavigation.customers_main"
                  class="w-12/12 md:w-3/12"
                  is-toggle="true"
                >
                  <template #header-text>
                    <h5
                      v-if="header.customersNavigation.customer_success_links"
                      class="p--stat-text"
                    >
                      {{
                        header.customersNavigation.customer_success_title || ''
                      }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.customersNavigation
                      .customer_success_links"
                    :key="index"
                    :content="link"
                    image="none"
                    type="no-desc"
                  />
                </nav-column>
                <nav-column
                  v-if="header.customersNavigation.customer_support_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{
                        header.customersNavigation.customer_support_title || ''
                      }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.customersNavigation
                      .customer_support_links"
                    :key="index"
                    :content="link"
                    image="none"
                    type="no-desc"
                  />
                </nav-column>
                <nav-column
                  v-if="header.customersNavigation.case_highlight_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{
                        header.customersNavigation.case_highlight_title || ''
                      }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.customersNavigation
                      .case_highlight_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="article"
                  />
                </nav-column>
                <nav-column
                  v-if="header.customersNavigation.top_fleet_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.customersNavigation.top_fleet_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.customersNavigation
                      .top_fleet_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="article"
                  />
                </nav-column>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            header.supportNavigation && header.supportNavigation.resources_main
          "
          class="flex flex-col"
        >
          <span
            :ref="header.supportNavigation.resources_main.title.toLowerCase()"
            class="subnav-link-title relative flex flex-row justify-between"
            :data-title="
              header.supportNavigation.resources_main.title.toLowerCase()
            "
          >
            <h4
              class="pointer-events-none mb-0 py-1 pl-[16px] pr-[16px] text-[24px]"
            >
              {{ header.supportNavigation.resources_main.title || '' }}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              class="subnav-icon pointer-events-none mr-1 self-center"
            >
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M16 1.49997L9 8.49994L2 1.49997"
                  stroke="#D9D9D9"
                  stroke-width="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect
                    width="10"
                    height="17"
                    fill="white"
                    transform="translate(17.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div
            class="wrapper__nav-drawer w-full overflow-hidden bg-white pl-[22px] pr-[22px] duration-100 ease-in-out"
            :class="[
              {
                'open fade-in':
                  isActive ===
                  header.supportNavigation.resources_main.title.toLowerCase(),
              },
            ]"
          >
            <div
              v-if="
                header.supportNavigation &&
                header.supportNavigation.resources_main
              "
              :id="
                header.supportNavigation.resources_main.title.toLowerCase() ||
                ''
              "
              class="drawer duration-100 ease-in-out md:pb-0"
              :class="{
                active:
                  isActive ===
                  header.supportNavigation.resources_main.title.toLowerCase(),
              }"
            >
              <div class="-mx-gap flex flex-wrap">
                <nav-column
                  v-if="header.supportNavigation.resources_main"
                  :content="header.supportNavigation.resources_main"
                  class="w-12/12 md:w-3/12"
                  is-toggle="true"
                >
                  <template #header-text>
                    <h5
                      v-if="header.supportNavigation.resources_links"
                      class="p--stat-text"
                    >
                      {{ header.supportNavigation.resources_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.supportNavigation
                      .resources_links"
                    :key="index"
                    :content="link"
                    image="none"
                    type="no-desc"
                  />
                </nav-column>
                <nav-column
                  v-if="header.supportNavigation.guides_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.supportNavigation.guides_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.supportNavigation
                      .guides_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="article"
                  />
                </nav-column>
                <nav-column
                  v-if="header.supportNavigation.webinar_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.supportNavigation.webinar_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.supportNavigation
                      .webinar_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="article"
                  />
                </nav-column>
                <nav-column
                  v-if="header.supportNavigation.innovation_links"
                  class="w-12/12 md:w-3/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.supportNavigation.innovation_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.supportNavigation
                      .innovation_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="article"
                  />
                </nav-column>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            header.companyNavigation && header.companyNavigation.company_main
          "
          class="flex flex-col"
        >
          <span
            :ref="header.companyNavigation.company_main.title.toLowerCase()"
            class="subnav-link-title relative flex flex-row justify-between"
            :data-title="
              header.companyNavigation.company_main.title.toLowerCase()
            "
          >
            <h4
              class="pointer-events-none mb-0 py-1 pl-[16px] pr-[16px] text-[24px]"
            >
              {{ header.companyNavigation.company_main.title || '' }}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              class="subnav-icon pointer-events-none mr-1 self-center"
            >
              <g clip-path="url(#clip0_1_2)">
                <path
                  d="M16 1.49997L9 8.49994L2 1.49997"
                  stroke="#D9D9D9"
                  stroke-width="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect
                    width="10"
                    height="17"
                    fill="white"
                    transform="translate(17.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div
            class="wrapper__nav-drawer w-full overflow-hidden bg-white pl-[22px] pr-[22px] duration-100 ease-in-out"
            :class="[
              {
                'open fade-in':
                  isActive ===
                  header.companyNavigation.company_main.title.toLowerCase(),
              },
            ]"
          >
            <div
              v-if="
                header.companyNavigation &&
                header.companyNavigation.company_main
              "
              :id="
                header.companyNavigation.company_main.title.toLowerCase() || ''
              "
              class="drawer duration-100 ease-in-out md:pb-0"
              :class="{
                active:
                  isActive ===
                  header.companyNavigation.company_main.title.toLowerCase(),
              }"
            >
              <div class="-mx-gap flex flex-wrap">
                <nav-column
                  v-if="header.companyNavigation.company_main"
                  :content="header.companyNavigation.company_main"
                  class="w-12/12 md:w-3/12"
                  is-toggle="true"
                >
                  <template #header-text>
                    <h5
                      v-if="header.companyNavigation.company_links"
                      class="p--stat-text"
                    >
                      {{ header.companyNavigation.company_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.companyNavigation
                      .company_links"
                    :key="index"
                    :content="link"
                    image="none"
                    type="no-desc"
                  />
                </nav-column>
                <nav-column
                  v-if="header.companyNavigation.news_links"
                  class="w-12/12 md:w-9/12"
                >
                  <template #header-text>
                    <h5 class="p--stat-text">
                      {{ header.companyNavigation.news_title || '' }}
                    </h5>
                  </template>
                  <LinksNavLink
                    v-for="(link, index) in header.companyNavigation.news_links"
                    :key="index"
                    :content="link"
                    image="image"
                    type="thirds"
                  />
                </nav-column>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: false,
        default: null,
      },
    },
    async setup() {
      const { $contentfulClient } = useNuxtApp();
      const { data: entry, error: entryError } = await useAsyncData(() => {
        return $contentfulClient.getEntry({
          'sys.id': '3rr4XBcFx4UVKeBpv6ZP7d',
        });
      });

      if (entryError.value) {
        throw createError({ statusCode: 404 });
      }

      return {
        header: entry.value,
      };
    },
    data() {
      return {
        isMobile: false,
        isActive: null,
        loading: true,
      };
    },
    created() {
      if (process.browser) {
        window.addEventListener('resize', this.mobileQuery);
        window.addEventListener('load', this.mobileQuery);
      }
    },
    beforeUnmount() {
      if (process.browser) {
        window.removeEventListener('resize', this.mobileQuery);
        window.removeEventListener('load', this.mobileQuery);
      }
    },
    mounted() {
      window.addEventListener('resize', this.onWindowResize);
    },
    methods: {
      mobileQuery() {
        this.isMobile = window.matchMedia('(max-width: 736px)').matches;
      },
      toggleMenu(event) {
        Object.keys(this.$refs).forEach((key) => {
          if (event.target.getAttribute('data-title') === key) {
            this.$refs[key].classList.toggle('open');
          } else {
            this.$refs[key].classList.remove('open');
          }
        });
      },
    },
  };
</script>
<style lang="scss">
  #subnav--main {
    @apply bg-white;

    .wrapper__nav-drawer {
      @apply h-0 transition-all duration-300;
      .link--blue {
        @apply font-medium;
      }

      .article--featured .link--blue {
        font-size: 12px;
        color: theme('colors.blue.DEFAULT');
      }
    }

    .drop--nav {
      > p {
        display: none;
      }
    }

    .subnav-link-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      .subnav-icon {
        @apply rotate-0 transition-all duration-300;
      }
      &.open {
        & + .wrapper__nav-drawer {
          @apply h-auto;
        }
        .subnav-icon {
          @apply rotate-180;
        }
      }
    }

    a {
      display: inherit;
    }

    .menu-icon {
      margin-left: auto;
      margin-right: 11px;
    }

    .wrapper__subnav {
      @media (min-width: 850px) {
        padding: 0 2%;
      }
    }

    .btn--nav {
      @media (max-width: 850px) {
        font-size: 16px;
        padding: 20px 17px;
      }
    }

    .p--stat-text {
      color: #979797;
      font-weight: bold;
      margin-bottom: theme('spacing.1');

      @media (max-width: 850px) {
        margin-top: theme('spacing.1');
      }
    }

    .drawer {
      p {
        line-height: 21px;
        font-size: 15px;
        color: #787878;
      }

      p:not(.link--title) {
        margin-bottom: 26px;
      }

      p.link--title {
        font-size: 19px;
        color: #00263e;
      }

      span {
        flex-shrink: 0;
        margin-right: 13px;
      }

      .border__left {
        border-left: 1px solid #e4e4e4;
        padding-left: 74px;

        @media (max-width: 850px) {
          padding-left: 11px;
          border-left: 0;
        }
      }

      @media (max-width: 850px) {
        .flex > .col:first-of-type {
          .p--stat-text {
            display: none;
          }
        }

        &.active {
          visibility: visible;
          opacity: 1;
          position: relative;
        }
      }
    }
  }
</style>
