<template>
  <div
    class="form-container h-full rounded-md p-2 md:h-[fit-content] md:w-[820px]"
  >
    <div class="form-content">
      <h3 class="text-center">{{ form.formTitle }}</h3>
    </div>
    <FormKit
      id="cloud-dashboard-form"
      type="form"
      :actions="false"
      #default="{ value, state }"
    >
      <div class="form-grid">
        <FormKit
          type="text"
          :label="form.jsonContent?.company || 'Organization Name'"
          ref="company"
          name="company"
          label-class="text-xs font-medium"
          :input-class="`w-full h-[45px] border-1 border-gray-light focus:outline-0 rounded-[5px] p-[5px] mt-[8px] valid-check relative ${
            validationStates.company.value === ValidationState.VALID
              ? '!border-green'
              : validationStates.company.value === ValidationState.INVALID
                ? '!border-red'
                : ''
          }`"
          message-class="text-red mt-[4px] text-xs font-medium"
          :validation-messages="{
            required:
              form.errorMessages?.company || 'Organization Name is required',
          }"
          validation="required"
          @blur="formKitState = state"
        >
          <template #suffix="{ value, state }">
            <IconsGreenCheckmark
              v-if="state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px]"
            />
            <FormsProcessingCheck
              v-else-if="state.validating && state.blurred"
              class="absolute right-[10px] top-[22px]"
            />
            <FormsInvalidCheck
              v-else-if="!state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px] h-auto w-auto"
            />
          </template>
        </FormKit>
        <FormKit
          type="text"
          :label="form.jsonContent?.name || 'Name'"
          name="name"
          ref="name"
          label-class="text-xs font-medium"
          :input-class="`w-full h-[45px] border-1 border-gray-light focus:outline-0 rounded-[5px] p-[5px] mt-[8px] valid-check relative ${
            validationStates.name.value === ValidationState.VALID
              ? '!border-green'
              : validationStates.name.value === ValidationState.INVALID
                ? '!border-red'
                : ''
          }`"
          message-class="text-red mt-[4px] text-xs font-medium"
          validation="required|matches:/^.+ .+/"
          :validation-messages="{
            required: form.errorMessages?.name || 'Name is required',
            matches: 'Please enter both first and last name',
          }"
          @blur="formKitState = state"
        >
          <template #suffix="{ value, state }">
            <IconsGreenCheckmark
              v-if="state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px]"
            />
            <FormsProcessingCheck
              v-else-if="state.validating && state.blurred"
              class="absolute right-[10px] top-[22px]"
            />
            <FormsInvalidCheck
              v-else-if="!state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px] h-auto w-auto"
            />
          </template>
        </FormKit>
        <FormKit
          type="email"
          :label="form.jsonContent?.email || 'Email'"
          ref="email"
          name="email"
          label-class="text-xs font-medium"
          :input-class="`w-full h-[45px] border-1 border-gray-light focus:outline-0 rounded-[5px] p-[5px] mt-[8px] valid-check relative ${
            validationStates.email.value === ValidationState.VALID
              ? '!border-green'
              : validationStates.email.value === ValidationState.INVALID
                ? '!border-red'
                : ''
          }`"
          message-class="text-red mt-[4px] text-xs font-medium"
          :validation-messages="{
            required: form.errorMessages?.email || 'Email is required',
            email: form.errorMessages?.emailInvalid || 'Email is invalid',
          }"
          validation="required|email|(1000)validateEmail"
          :validation-rules="emailRules"
          @blur="formKitState = state"
        >
          <template #suffix="{ value, state }">
            <IconsGreenCheckmark
              v-if="state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px]"
            />
            <FormsProcessingCheck
              v-else-if="state.validating && state.blurred"
              class="absolute right-[10px] top-[22px]"
            />
            <FormsInvalidCheck
              v-else-if="!state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px] h-auto w-auto"
            />
          </template>
        </FormKit>
        <FormKit
          type="tel"
          :label="form.jsonContent?.phone || 'Phone'"
          ref="telephone"
          name="phone"
          label-class="text-xs font-medium"
          :input-class="`w-full h-[45px] border-1 border-gray-light focus:outline-0 rounded-[5px] p-[5px] mt-[8px] valid-check relative ${
            validationStates.phone.value === ValidationState.VALID
              ? '!border-green'
              : validationStates.phone.value === ValidationState.INVALID
                ? '!border-red'
                : ''
          }`"
          message-class="text-red mt-[4px] text-xs font-medium"
          :validation-messages="{
            required: form.errorMessages?.phone || 'Phone is required',
            matches: form.errorMessages?.phoneMatch || 'Phone is invalid',
          }"
          validation="required | validPhone | minLength |(1000)validatePhoneNumber"
          :validation-rules="phoneRules"
          validation-visibility="blur"
          @blur="formKitState = state"
        >
          <template #suffix="{ value, state }">
            <IconsGreenCheckmark
              v-if="state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px]"
            />
            <FormsProcessingCheck
              v-else-if="state.validating && state.blurred"
              class="absolute right-[10px] top-[22px]"
            />
            <FormsInvalidCheck
              v-else-if="!state.valid && !state.validating && state.blurred"
              class="absolute right-[15px] top-[22px] h-auto w-auto"
            />
          </template>
        </FormKit>
      </div>
      <div class="button-group mt-space">
        <label class="text-xs font-medium">{{
          form.jsonContent?.assetCount || 'How many vehicles do you have?'
        }}</label>
        <div class="flex w-full flex-wrap pt-1/2">
          <div
            v-for="size in fleetSizes"
            :key="size"
            class="m-1/4 flex w-[46%] items-center md:mx-1/4 md:w-[18%] md:justify-center"
          >
            <input
              type="radio"
              :id="`fleetSize-${size}`"
              :value="size"
              v-model="selectedFleetSize"
              class="hidden-radio"
            />
            <button
              :class="[
                'fleet-size-button w-full cursor-pointer rounded border-1 border-gray-light p-[10px]',
                { selected: selectedFleetSize === size },
              ]"
              @click="selectFleetSize($event, size)"
            >
              {{ size }}
            </button>
          </div>
        </div>
        <p v-if="fleetSizeError" class="mt-[4px] text-xs font-medium text-red">
          {{
            form.errorMessages?.assetCount ||
            'Please select a number of vehicles'
          }}
        </p>
      </div>
      <div class="form-legal-text">
        <RichTextRenderer
          v-if="form.legalText"
          :document="form.legalText"
          :node-renderers="nodeRenderers"
        />
      </div>
      <FormKit
        type="submit"
        :label="form.submitButtonText"
        :input-class="`submit-button text-white border-1 uppercase rounded tracking-wide text-xs text-center font-bold min-w-[200px] pt-[14px] pb-[12px] px-[20px] cursor-pointer mx-auto ${
          isFormValid
            ? 'bg-blue border-blue'
            : 'bg-gray-light border-gray-light disabled-button'
        }`"
        :disabled="!isFormValid"
        @click="submitHandler($event, value)"
      >
        <template v-if="isSubmitting">
          <IconsSpinner color="text-white" />
        </template>
        <template v-else>
          {{ form.submitButtonText }}
        </template>
      </FormKit>
    </FormKit>
  </div>
</template>

<script setup>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { usePricingFormStore } from '~/stores/pricing-form';
  import { nodeRenderers } from '~/utils/contentful-helpers';
  import { LOCALE_CODE_TO_COUNTRY } from '~/constants/locales';
  import { useGetValidationState } from '~/composables/formkit-valid-state';
  import { ValidationState } from '~/types/enums/validation-state';

  // Get props
  const props = defineProps({
    form: {
      type: Object,
      required: true,
    },
  });

  // Get Helpers
  const { analyticsTrack } = useAnalytics();
  const { getQuantcastID, getUTMCookies, getSegmentId } = useAnalyticsHelpers();
  const { $emitter } = useNuxtApp();
  const { fullPath } = useRoute();
  const { locale } = useI18n();
  const store = usePricingFormStore();
  const { isMobile } = useDevice();
  const { isProduction } = useRuntimeConfig().public;

  // Init Vars
  const telephone = ref(null);
  const email = ref(null);
  const company = ref(null);
  const name = ref(null);
  const fleetSizes = ['1 - 5', '6 - 29', '30 - 499', '500 - 4,999', '5000+'];
  const selectedFleetSize = ref(null);
  const fleetSizeError = ref(false);
  const actionURL = ref(undefined);
  const combinedFormValues = ref({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    phone: '',
    num_vehicles_range: '',
    description: 'Demo Form Lead',
    channel_name: 'Paid Fleet',
    thankyou: props.form.postUrl,
    session_id: '',
  });
  const isSubmitting = ref(false);
  const localeSite = LOCALE_CODE_TO_COUNTRY[locale.value.toLowerCase()];
  const isEmailValid = ref(false);
  const isPhoneValid = ref(false);
  const formKitState = ref({});

  // Run Advertising and Analytics
  const runAdvertising = () => {
    const cookies = getUTMCookies();

    cookies.forEach((cookie) => {
      combinedFormValues.value[cookie.key] = cookie.value;
    });

    combinedFormValues.value.quant_id = getQuantcastID();
  };

  const runAnalytics = () => {
    const mixpanelID = getSegmentId();
    const segmentID = getSegmentId();

    combinedFormValues.value.mixpanel_id = mixpanelID || '';
    combinedFormValues.value.segment_id = segmentID || '';
  };

  // Action URL
  const generateFormActionURL = () => {
    const prefix = locale.value === 'en-US' ? '' : '/' + locale.value;
    const hostname = window.location.hostname;
    let base;

    if (hostname.includes('localhost')) {
      base = `http://localhost:3000/proxy`;
    } else if (hostname.includes('amplifyapp')) {
      base = `https://${hostname}/proxy`;
    } else if (hostname.includes('samsara-staging')) {
      base = 'https://www.samsara-staging.com';
    } else if (hostname.includes('samsara-sandbox')) {
      base = 'https://www.samsara-sandbox.com';
    } else {
      base = 'https://www.samsara.com';
    }

    return `${base}${prefix}/pricing-form-submit`;
  };

  // Geo
  const geoLocate = async () => {
    const geo = await getGeo();

    if (Object.keys(geo).length) {
      if (geo.isEU) {
        store.isEU = true;
        setGDPRoptInPreferences();
      }

      store.geoFetched = true;
    }
  };

  // Form Handlers
  const selectFleetSize = (event, value) => {
    event.preventDefault();
    selectedFleetSize.value = value;
    fleetSizeError.value = false;
  };

  const isFormValid = computed(() => {
    return (
      formKitState.value.valid &&
      isEmailValid.value &&
      isPhoneValid.value &&
      selectedFleetSize.value
    );
  });

  const validationStates = {
    company: useGetValidationState(company),
    name: useGetValidationState(name),
    email: useGetValidationState(email),
    phone: useGetValidationState(telephone),
  };

  const submitHandler = async (event, formValues) => {
    event.preventDefault();
    if (!isFormValid.value) {
      if (!selectedFleetSize.value) {
        fleetSizeError.value = true;
      }
      return;
    }
    isSubmitting.value = true;
    const { firstname, lastname } = splitName(formValues.name);
    combinedFormValues.value.first_name = firstname;
    combinedFormValues.value.last_name = lastname;
    combinedFormValues.value.num_vehicles_range = selectedFleetSize.value;
    combinedFormValues.value.company = formValues.company;
    combinedFormValues.value.phone = store.formFields.phone;
    combinedFormValues.value.email = formValues.email;
    combinedFormValues.value.session_id =
      sessionStorage.getItem('_session_id') || '';

    // Convert form values to FormData
    const formData = new FormData();
    Object.keys(combinedFormValues.value).forEach((key) => {
      formData.append(key, combinedFormValues.value[key] ?? '');
    });

    try {
      const response = await fetch(actionURL.value, {
        method: 'POST',
        body: formData,
        redirect: 'manual',
      });

      if (
        response.ok ||
        (response.status === 0 && response.type === 'opaqueredirect')
      ) {
        $emitter.emit('formSubmitted', true);
        analyticsTrack('Form Submitted', {
          form_type: 'demo',
          form_url: fullPath,
          is_mobile: isMobile,
          locale_site: localeSite,
        });
      } else {
        isSubmitting.value = false;
      }
    } catch (error) {
      isSubmitting.value = false;
    }
  };

  // Methods
  const splitName = (name) => {
    const nameParts = name.trim().split(/\s+/);
    if (nameParts.length > 1) {
      return { firstname: nameParts.shift(), lastname: nameParts.join(' ') };
    }
    return { firstname: name, lastname: '' };
  };

  const phoneRules = {
    validPhone({ value }) {
      const isValid =
        /^\s*(\+?\d{1,3})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}\s*$/.test(
          value
        );
      isPhoneValid.value = isValid;
      return isValid;
    },
    minLength({ value }) {
      const numbers = value.replace(/\D/g, '');
      const isValid = numbers.length >= 10;
      isPhoneValid.value = isValid;
      return isValid;
    },
    async validatePhoneNumber({ value }) {
      const sanitizedPhoneNumber = value.trim().replace(/\D/g, '');
      if (isProduction) {
        try {
          const response = await $fetch('/api/validate/ipqs', {
            method: 'post',
            body: {
              input: sanitizedPhoneNumber,
              type: 'phone',
              locale: locale.value,
            },
          });

          if (response.message === true) {
            store.formFields.phone = response.data.result.formatted;
            isPhoneValid.value = true;
            return true;
          } else {
            store.formFields.phone = sanitizedPhoneNumber;
            analyticsTrack('Form Step Validation Failed', {
              form_type: 'Short Form',
              form_url: fullPath,
              locale_site: locale.value,
              validation_data: {
                ipqs_phone_valid: response.data.result.valid,
                ipqs_phone_active_status: response.data.result.active,
                ipqs_phone_fraud_score: response.data.result.fraud_score,
              },
            });
            isPhoneValid.value = false;
            return false;
          }
        } catch (error) {
          report(error);
          store.formFields.phone = sanitizedPhoneNumber;
          isPhoneValid.value = true;
          return true;
        }
      } else {
        store.formFields.phone = sanitizedPhoneNumber;
        isPhoneValid.value = true;
        return true;
      }
    },
  };

  const emailRules = {
    async validateEmail({ value }) {
      const email = value;
      if (isProduction) {
        try {
          const response = await $fetch('/api/validate/ipqs', {
            method: 'post',
            body: { input: email, type: 'email' },
          });

          if (response.message === true) {
            isEmailValid.value = true;
            return true;
          } else {
            analyticsTrack('Form Step Validation Failed', {
              form_type: formType,
              form_url: fullPath,
              locale_site: locale.value,
              step_name: props.step.name,
              step_text: props.step.title,
              validation_data: {
                ipqs_email_valid: response.data.result.valid,
                ipqs_email_disposable: response.data.result.disposable,
                ipqs_email_fraud_score: response.data.result.fraud_score,
              },
            });
            isEmailValid.value = false;
            return false;
          }
        } catch (error) {
          report(error);
          isEmailValid.value = true;
          return true;
        }
      } else {
        isEmailValid.value = true;
        return true;
      }
    },
  };

  // Lifecycle
  onMounted(() => {
    actionURL.value = generateFormActionURL();
    geoLocate();

    if (store.advertisingOptIn) {
      runAdvertising();
    }

    if (store.analyticsOptIn) {
      runAnalytics();
    }

    // Add experiment name to form values
    combinedFormValues.value.experimentName = store.experimentName;

    // Track form started
    analyticsTrack('Form Started', {
      form_type: 'demo',
      form_url: fullPath,
      is_mobile: isMobile,
      locale_site: localeSite,
    });
  });
</script>

<style lang="scss" scoped>
  .form-container {
    @apply absolute z-[10001] bg-white;
  }

  .formkit-outer {
    @apply p-[10px_10px_0px_0px];
    &[data-family='button'] {
      @apply pt-1;
    }
  }

  .form-grid {
    @apply grid grid-cols-2 gap-[16px];
  }

  .hidden-radio {
    @apply hidden;
  }

  .fleet-size-button {
    &.selected {
      @apply border-2 border-blue font-medium text-blue;
    }
  }

  .form-legal-text {
    :deep(p) {
      @apply mb-0 text-center text-xxs leading-4;
      &:first-child {
        @apply mb-1.5 mt-1;
      }
    }
  }

  :deep(.formkit-outer) {
    @apply p-0;
    &[data-type='submit'] {
      @apply flex justify-center;
    }
  }

  :deep(.formkit-inner) {
    @apply relative;
  }

  .disabled-button {
    cursor: default !important;
  }
</style>
